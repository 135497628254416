import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaInbox } from "react-icons/fa6";
import { fetchAllData } from "../utility/fetcher";

export const Citations = () => {
    const [loading, setLoading] = useState(true);
    const [allAppealedCitations, setAllAppealedCitations] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [citations, setCitations] = useState([]);
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const uniName = orgData.orgName;

    // Yuck
    const parseData = () => {
        let users = []
        let appealedCitations = [];
        let allCitations = [];
        for (let i in allUsers) {
            const user = allUsers[i];
            const citations = allUsers[i].citations;
            for (let j in citations) {
                if (citations[j].location === uniName) {
                    const appeals = citations[j].appeals;
                    allCitations.push(citations[j]);
                    for (let k in appeals) {
                        if (appeals[k].status === "pending") {
                            users.push(user);
                            appealedCitations.push(citations[j]);
                        }
                    }
                }
            }
        }

        setCitations(allCitations);
        sessionStorage.setItem("appealingUsers", JSON.stringify(users));
        sessionStorage.setItem("appealedCitations", JSON.stringify(appealedCitations));
        return appealedCitations;
    }

    const singleCitation = (citation) => {
        const appeals = citation.appeals;
        let color = "bg-red-100"

        if (citation.paid) {
            color = "bg-green-100"
        } else {
            for (let i in appeals) {
                if (appeals[i].status === "pending") {
                    color = "bg-yellow-100"
                    break;
                } else if (appeals[i].status === "Denied") {
                    color = "bg-red-100"
                    break;
                } else if (appeals[i].status === "Approved") {
                    color = "bg-gray-100"
                    break;
                }
            }
        }


        return (
            <Link to={`/analytics/citations/${citation.citationID}`} key={citation.CitationID} className={`flex flex-col ${color} p-4 rounded-xl hover:shadow-md transition-all duration-300 ease-in-out`} onClick={() => sessionStorage.setItem("currentCitation", JSON.stringify(citation))}>
                <h1 className="text-xl font-bold">{citation.citationID}</h1>
                <p className="text-md">Plate: {citation.licensePlate}</p>
                <p className="text-md">Date: {new Date(citation.timeWritten).toLocaleString()}</p>
                <p className="text-md">Type: {citation.type}</p>
                <p className="text-md">Spot: {citation.lot}-{citation.spot}</p>
                <p className="text-md">Fee: ${citation.fee}</p>
            </Link>
        );
    }

    // Start by fetching data
    useEffect(() => {
        const urls = { "userData": "/general/spotUsers" };
        (async () => {
            try {
                const data = await fetchAllData(urls);
                setAllUsers(data.userData);
                setLoading(false);
            } catch (error) {
                console.error("Error storing data:", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const appealedCitations = parseData();
        setAllAppealedCitations(appealedCitations);
        // eslint-disable-next-line 
    }, [allUsers]);

    return (
        <div className="fcc pl-20 font-rubik">
            <Link to={"/analytics/appeals"} className="relative w-full mt-8 px-8 flex justify-end text-gray-500 hover:drop-shadow-md transition-all duration-100 ease-in-out">
                <FaInbox size={32} className="absolute mt-2 mr-1" />
                <div className={`fcc w-5 h-5 relative rounded-full bg-red-400 transition-all duration-500 ease-in-out ${allAppealedCitations.length === 0 ? "opacity-0" : "opacity-100"}`}>
                    <p className="text-white text-xs absolute">{allAppealedCitations.length}</p>
                </div>
            </Link>
            {loading ? <h1 className="mt-20 w-full text-center">Loading...</h1> :
                <div className="frc gap-4 w-full mt-10 pl-24 pr-4">
                    {citations.length === 0 ? <h1>No citations found.</h1> :
                        citations.map((citation) => singleCitation(citation))}
                </div>
            }
        </div>
    );
}