// This page is just for the map component. It pulls data from MapBox (open source map API) and displays the map on the screen.
// Each polygon is a parking spot. The color of the polygon is determined by the violation type.
// The map is interactive and can be zoomed in and out of.
// The map has default settings that can be changed by passing in props such as zoom and coordinates.

import React, { useEffect, useState } from "react";
import TitleCard from "./TitleCard";
import mapboxgl from "mapbox-gl";
import { fetchAllData, postData } from "../utility/fetcher";
import { SpotEditor } from "../settings/SpotEditor";
import { Toaster, toast } from "sonner";
import lotImage from "../assets/lot.jpg"
import 'mapbox-gl/dist/mapbox-gl.css';
import "./Map.css";

// Mapbox Tokens
mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmhhZ2VydHkzMiIsImEiOiJjbHYyd25vdGEwbXhoMmtwN3hkZGZ6ZmxmIn0.8j4S80-LC8wX7mLHwWrc_g";

// Map Creation
const Map = ({
    startSpot,
    styling,
    buttons,
    itemClickable,
    zoom,
    highlight,
    lot
}) => {
    //const navigate = useNavigate();
    var listData = JSON.parse(sessionStorage.getItem("violationData"));
    const [currentSpotData, setCurrentSpotData] = useState([])
    const [currentLevel, setCurrentLevel] = useState(0);
    const [selectedViolation, setSelectedViolation] = useState(null);
    const [selectedPolygons, setSelectedPolygons] = useState(new Set());
    const [changedTypes, setChangedTypes] = useState([]);
    const [isMapReady, setIsMapReady] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [activeCamera, setActiveCamera] = useState({});
    const [map, setMap] = useState(null);
    const [isStyleLoaded, setIsStyleLoaded] = useState(false);
    const [currentListData, setCurrentListData] = useState(listData);
    const currentOrgData = JSON.parse(localStorage.getItem("orgData"))
    const orgID = currentOrgData.orgID
    const orgCoordinates = JSON.parse(currentOrgData["orgCoordinates"]); // Pull in the coordinates from the uniDictionary
    const defaultZoom = currentOrgData["defaultMapZoom"]; // Pull in the zoom from the uniDictionary
    const cameras = JSON.parse(sessionStorage.getItem("cameraArray"));

    const [levelStates, setLevelStates] = useState({});

    let maxLevel = 0;
    let minLevel = 0;

    for (let i in listData) {
        if (parseInt(listData[i].level) > maxLevel) {
            maxLevel = parseInt(listData[i].level)
        }
        if (parseInt(listData[i].level) < minLevel) {
            minLevel = parseInt(listData[i].level)
        }
    }

    let levels = {};
    for (let i = minLevel; i <= maxLevel; i++) {
        levels[i] = false;
        levels[0] = true;
    }

    useEffect(() => {
        setLevelStates(levels);
        let intervalId = null;

        const getNewData = async () => {
            const newData = await fetchAllData({ "spotData": `/${orgID}/spots` });
            setCurrentListData(newData.spotData);
        };

        // Every ten seconds, update the map data. This is a very expensive operation. Not in regard to the code, but in regard to the cost of the API calls.
        const startPeriodicDataGetter = () => {
            intervalId = setInterval(async () => {
                await getNewData();
            }, 2000); //! This has direct influence on our costs. Think deeply if you are wanting to change this.
        };

        const stopPeriodicDataGetter = () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
                intervalId = null;
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                startPeriodicDataGetter();
            } else {
                stopPeriodicDataGetter();
            }
        };

        // Set up event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Initial start when the component is first mounted
        if (document.visibilityState === 'visible') {
            startPeriodicDataGetter();
        }

        // Cleanup function
        return () => {
            stopPeriodicDataGetter();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line
    }, []);

    if (listData.length === 0) {
        listData = []
    }

    // Default settings
    const startSpotCoordinates = startSpot ? startSpot : orgCoordinates;
    const mapZoom = zoom ? zoom : defaultZoom;
    const colorScheme = "mapbox://styles/mapbox/light-v10";
    // eslint-disable-next-line
    const itemClickableBool = itemClickable === undefined ? true : itemClickable;
    // const highlightedItem = highlight ? highlight : null;
    // const lotNumber = lot ? lot : null;
    const baseZoom = 18; // Line Size and base zoom have an inverse relationship
    const baseWidth = 1;
    const mapCss = styling ? styling : {
        flex: "1",
        top: "0%",
        right: "0px",
        touchAction: "none",
        width: "102vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed"
    };

    let availableSpotTypes = [];
    const orgPermits = currentOrgData.permitTypes;
    for (let i in orgPermits) {
        availableSpotTypes.push(orgPermits[i].description)
    }

    // When clicked, naviagate to the violation page of that violation
    const handleItemClick = ({ licensePlate, spot, timeParked, vehicleType, permitNumber, violationType, coordinates, lot, ticketWritten, spotID, cameras, permitType, level }) => {
        setSelectedViolation({ licensePlate, spot, timeParked, vehicleType, permitNumber, violationType, coordinates, lot, ticketWritten, spotID, cameras, permitType, level });
    };

    const handleCloseViolationCard = () => {
        const violationColor = selectedViolation.ticketWritten === true ? "#9C9C9C" : selectedViolation.violationType === "" ? "#aad7a4" : "#D1807D";
        map.setPaintProperty(`polygon-layer-${selectedViolation.licensePlate}`, "fill-color", violationColor);
        setSelectedViolation(null);
    };

    // Map Object
    useEffect(() => {
        const newMap = new mapboxgl.Map({
            container: "map-container",
            style: colorScheme,
            center: startSpotCoordinates,
            zoom: mapZoom,
            attributionControl: false,
        });

        // Create map
        setMap(newMap);
        setIsMapReady(true);

        // Add features
        newMap.on("load", async () => {
            setIsStyleLoaded(true);
            await addAllPolygons(newMap);
            await addCameras(newMap);
            addLines(newMap);
        });

        // Disable pointer cursor
        newMap.getCanvas().style.cursor = "default";

        // Add line cursor
        newMap.on("mousemove", (e) => {
            const features = newMap.queryRenderedFeatures(e.point);
            const polygonFeatures = features.filter((feature) => feature.layer.type === "fill");
            newMap.getCanvas().style.cursor = polygonFeatures.length ? "pointer" : "default";
        });

        return () => {
            newMap.remove();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (map && isStyleLoaded) {
            if (startSpot) {
                map.flyTo({ center: startSpot, essential: true });
            }

            // if (highlight) {
            //     // Reset all polygons to their original color
            //     currentListData.forEach((item) => {
            //         const licensePlate = item.licensePlate;
            //         const ticketWritten = item.ticketWritten;
            //         const violationType = item.violationType;
            //         let fillColor = "#9C9C9C";

            //         if (ticketWritten === true) {
            //             fillColor = "#9C9C9C";
            //         } else if (violationType === "N/A") {
            //             fillColor = "#aad7a4";
            //         } else {
            //             fillColor = "#D1807D";
            //         }

            //         map.setPaintProperty(`polygon-layer-${licensePlate}`, "fill-color", fillColor);
            //     });

            //     // Highlight the selected item
            //     map.setPaintProperty(`polygon-layer-${highlight}`, "fill-color", "#A43C3C");
            // }
        }
    }, [startSpot, highlight, map, currentListData, isStyleLoaded]);

    useEffect(() => {
        if (map && isStyleLoaded) {
            addAllPolygons(map);
        }
        // eslint-disable-next-line
    }, [currentListData, currentLevel]);

    useEffect(() => {
        if (map && isStyleLoaded) {
            addLines(map);
        }
        // eslint-disable-next-line
    }, [currentLevel]);

    const addAllPolygons = async (map) => {
        // Store selected polygons as a state variable
        let selectedPolygons = [];

        // Convert your list data into GeoJSON features
        const coordinatesArray = currentListData.map((item) => [
            JSON.parse(item.coordinates),
            item.licensePlate,
            item.lot,
            item.permitNumber,
            item.spot,
            item.ticketWritten,
            item.timeParked,
            item.vehicleType,
            item.violationType,
            item.spotID,
            item.cameras,
            item.permitType,
            item.level,
        ]);

        const features = coordinatesArray.map(([coordinates, licensePlate, lot, permitNumber, spot, ticketWritten, timeParked, vehicleType, violationType, spotID, cameras, permitType, level]) => {
            // const lots = currentOrgData["lots"];
            let onOffStatus = true //lots[lot - 1].onOff;

            let fillColor;
            if (onOffStatus === false) {
                fillColor = "#aad7a4";
            } else if (licensePlate === "") {
                fillColor = "transparent";
            } else if (ticketWritten === true) {
                fillColor = "#9C9C9C";
            } else if (violationType === "") {
                fillColor = "#aad7a4";
            } else {
                fillColor = "#E57373";
            }

            // let fillOpacity = 1;
            // if (currentLevel !== level || currentLevel === undefined) {
            //     if (fillColor !== "transparent") {
            //         fillColor = "#9C9C9C";
            //         fillOpacity = 0.5;
            //     }
            // }

            if (currentLevel.toString() === level) {
                return {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        coordinates: [coordinates]
                    },
                    properties: {
                        id: `polygon-layer-${spotID}`,
                        violationType: violationType,
                        licensePlate: licensePlate,
                        spot: spot,
                        lot: lot,
                        timeParked: timeParked,
                        vehicleType: vehicleType,
                        permitNumber: permitNumber,
                        coordinates: coordinates,
                        ticketWritten: ticketWritten,
                        fillColor: fillColor,
                        // fillOpacity: fillOpacity,
                        spotID: spotID,
                        cameras: cameras,
                        permitType: permitType,
                        level: level
                    }
                };
            } else {
                return null
            }
        }).filter(feature => feature !== null);

        const featureCollection = {
            type: "FeatureCollection",
            features: features
        };

        // Add or update the source and layer on the map
        if (map.getSource('polygons')) {
            map.getSource('polygons').setData(featureCollection);
        } else {
            map.addSource('polygons', {
                type: 'geojson',
                data: featureCollection
            });

            map.addLayer({
                id: 'polygon-layer',
                type: 'fill',
                source: 'polygons',
                paint: {
                    'fill-color': ['get', 'fillColor'],
                    'fill-opacity': ['get', 'fillOpacity'],
                    'fill-outline-color': 'transparent',
                }
            });
        }

        // Handle click on polygon layer
        map.on('click', 'polygon-layer', (e) => {
            const clickedFeature = e.features[0];
            const properties = clickedFeature.properties;

            // Handle multi-select with Ctrl or Cmd key
            const currentSelectedPolygons = selectedPolygons; // No need for Array.isArray check

            if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
                const isSelected = currentSelectedPolygons.some(polygon => polygon.spotID === properties.spotID);
                const updatedSelectedPolygons = isSelected
                    ? currentSelectedPolygons.filter(polygon => polygon.spotID !== properties.spotID) // Deselect if already selected
                    : [...currentSelectedPolygons, properties]; // Add to selection

                selectedPolygons = updatedSelectedPolygons;

            } else {
                // Clear previous selections and select the clicked polygon
                selectedPolygons = [properties];
            }

            // Update UI based on selection
            if (selectedPolygons.length === 1) {
                // Handle single polygon selection
                const selectedFeature = selectedPolygons[0];
                if (selectedFeature) {
                    const { licensePlate, spot, timeParked, vehicleType, ticketWritten, permitNumber, violationType, coordinates, lot, spotID, cameras, permitType, level } = selectedFeature;
                    handleItemClick({ licensePlate, spot, timeParked, vehicleType, ticketWritten, permitNumber, violationType, coordinates, lot, spotID, cameras, permitType, level });
                }
                hideAllSelectionUI(); // Hide multi-selection UI
            } else if (selectedPolygons.length > 1) {
                // Handle multiple polygons selection
                showMultiSelectionDiv(selectedPolygons);
            } else {
                // No polygons selected
                saveToDatabase();
                hideAllSelectionUI(); // Hide all UI when no polygons are selected
            }

            // Reset all polygon colors
            map.querySourceFeatures('polygons').forEach(({ properties }) => {
                map.setFeatureState({ source: 'polygons', id: properties.id }, { fillColor: properties.fillColor });
            });

            // Highlight selected polygons
            selectedPolygons.forEach(polygon => {
                map.setFeatureState({ source: 'polygons', id: `polygon-layer-${polygon.spotID}` }, { fillColor: "#4C9A2A" });
            });
        });

        // Handle clicks outside polygons to deselect
        map.on('click', (e) => {
            const features = map.queryRenderedFeatures(e.point, { layers: ['polygon-layer'] });

            if (!features.length) {
                // Clear all selections
                selectedPolygons = [];
                setSelectedPolygons(selectedPolygons); // Ensure UI state is updated
                setSelectedViolation(null); // Clear the selected violation
                hideAllSelectionUI(); // Hide UI

                // Reset all polygon colors
                map.querySourceFeatures('polygons').forEach(({ properties }) => {
                    map.setFeatureState({ source: 'polygons', id: properties.id }, { fillColor: properties.fillColor });
                });
            }
        });
    };

    // Function to hide UI elements
    const hideAllSelectionUI = () => {
        // Hide the single-selection UI
        const singleSelectionDiv = document.getElementById("single-selection-div");
        if (singleSelectionDiv) {
            singleSelectionDiv.style.display = "none";
        }

        // Hide the multi-selection UI
        const multiSelectionDiv = document.getElementById("multi-selection-div");
        if (multiSelectionDiv) {
            saveToDatabase();
            multiSelectionDiv.parentNode.removeChild(multiSelectionDiv);;
        }

        // Optionally clear out any text or data within the UI elements
        if (singleSelectionDiv) {
            singleSelectionDiv.innerHTML = "";
        }

        if (multiSelectionDiv) {
            multiSelectionDiv.innerHTML = "";
        }
    };

    // Handle type change for selected spots
    const handleTypeChange = (e, selectedPolygons) => {
        const newType = e.target.value;

        // Update the type for each selected spot
        const updatedSpotData = currentSpotData.map((spot) => {
            if (selectedPolygons.some(selected => selected.spotID === spot.spotID)) {
                return { ...spot, type: newType };
            }
            return spot;
        });

        const newBuild = selectedPolygons.map(selectedSpot => ({
            spotID: selectedSpot.spotID,
            permitType: newType
        }));



        /*
        This isn't working quite yet. It appears to be saving the right data to newBuild but it's not updating the changedTypes state. Because changedTypes is not updating, the saveToDatabase function is not thinking to post anything to the database.
        */


        const ogChanges = changedTypes
        for (let i in newBuild) {
            const spot = newBuild[i]
            const index = ogChanges.findIndex((change) => change.spotID === spot.spotID)
            if (index !== -1) {
                ogChanges[index] = spot
            } else {
                ogChanges.push(spot)
            }
        }

        setChangedTypes(ogChanges);
        setCurrentSpotData(updatedSpotData);
        console.log(changedTypes)
    };

    // Get the type of the selected spots for dropdown initialization
    const getSelectedSpotType = () => {
        const firstSelectedSpot = selectedPolygons[0];
        return firstSelectedSpot?.permitType || ""; // Adjust to match actual property
    };

    // Show the UI for multiple selected polygons
    const showMultiSelectionDiv = (selectedPolygons) => {
        let div = document.getElementById('multi-selection-div');

        if (!div) {
            div = document.createElement('div');
            div.id = 'multi-selection-div';
            document.body.appendChild(div);
        }

        const titleCard = document.getElementById('titleCard');

        if (selectedPolygons.length > 1) {
            if (titleCard) {
                titleCard.style.display = 'none';
            }

            const selectedSpotType = getSelectedSpotType();

            // Show the UI for multiple selected polygons
            div.innerHTML = `
            <div style="
                position: fixed;
                bottom: 0px;
                left: 260px;
                transform: translate(-50%, -50%);
                background-color: rgba(0, 0, 0, 0.7);
                color: white;
                padding: 20px;
                border-radius: 10px;
                font-size: 24px;
                font-weight: bold;
                text-align: center;
                z-index: 9999;
            ">
                ${selectedPolygons.length} stalls selected
                <div class="multi-selection-dropdown" style="margin-top: 20px;">
                    <label for="spotType" style="font-size: 16px;">Spot Type:</label>
                    <select id="spotType" style="padding: 5px; border-radius: 5px; margin-left: 10px;">
                        ${availableSpotTypes.map(type => `
                            <option value="${type}" ${type === selectedSpotType ? 'selected' : ''}>${type}</option>
                        `).join('')}
                    </select>
                </div>
            </div>
        `;

            // Add event listener to handle type change
            const spotTypeSelect = div.querySelector('#spotType');
            spotTypeSelect.addEventListener('change', (e) => {
                handleTypeChange(e, selectedPolygons);
            });

            div.style.display = 'block'; // Ensure the div is visible

        } else if (selectedPolygons.length === 1) {
            // Hide the multi-selection div when only one polygon is selected
            if (div) {
                div.style.display = 'none';
            }

            // Show the title card again
            if (titleCard) {
                titleCard.style.display = 'block';
            }
        } else {
            // Hide both the multi-selection div and show the title card when no polygons are selected
            if (div) {
                div.style.display = 'none';
            }

            if (titleCard) {
                titleCard.style.display = 'block';
            }
        }
    };

    // Runs drilled prop function to save data to database
    const saveToDatabase = async () => {
        console.log("Changed Types:", changedTypes);
        closeEditor("save", changedTypes);
    };

    const addCameras = async (map) => {
        // Function to open the spot editor
        window.openSpotEditor = function (title) {
            console.log("Opening spot editor for lot:", title);

            const camera = cameras.filter((cam) => cam.title === title)[0];
            console.log(camera)

            sessionStorage.setItem("currentCamera", JSON.stringify(camera));
            setActiveCamera(camera);
            setEditorOpen(true);
        };

        const openSpotEditor = (title) => {
            console.log("Opening spot editor for cam:", title);

            const camera = cameras.filter((cam) => cam.name === title)[0];

            sessionStorage.setItem("currentCamera", JSON.stringify(camera));
            setActiveCamera(camera);
            setEditorOpen(true);
        };

        const cameraFeatures = []
        for (let i in cameras) {
            const camera = cameras[i]
            const coordinateString = camera.coordinates
            // Remove the square brackets
            // eslint-disable-next-line
            const noBrackets = coordinateString.replace(/[\[\]]/g, "");
            const strArray = noBrackets.split(",");
            const coordinateArray = strArray.map(Number);
            const feature = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: coordinateArray
                },
                properties: {
                    title: camera.name,
                    description: camera.direction,
                    lotNum: camera.lotNum,
                    resolution: camera.resolution,
                    coordinates: camera.coordinates
                }
            }
            cameraFeatures.push(feature)
        }

        const geojson = {
            type: 'FeatureCollection',
            features: cameraFeatures
        };

        // Define SVG as a string
        const cameraIconSvg = `
            <svg width="100%" height="100%" viewBox="0 0 60 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.57143 0.714355C6.29814 0.714355 4.11797 1.61741 2.51051 3.22487C0.903059 4.83232 0 7.0125 0 9.28578V26.4286C0 28.7019 0.903059 30.8821 2.51051 32.4896C4.11797 34.097 6.29814 35.0001 8.57143 35.0001H30C32.2733 35.0001 34.4535 34.097 36.0609 32.4896C37.6684 30.8821 38.5714 28.7019 38.5714 26.4286V9.28578C38.5714 7.0125 37.6684 4.83232 36.0609 3.22487C34.4535 1.61741 32.2733 0.714355 30 0.714355H8.57143ZM60 3.92864C59.9994 3.29333 59.8106 2.67243 59.4574 2.14436C59.1042 1.61629 58.6024 1.20475 58.0154 0.961697C57.4284 0.718648 56.7826 0.655 56.1594 0.77879C55.5363 0.90258 54.9638 1.20826 54.5143 1.65721L45.9429 10.2286C45.3399 10.8309 45.0007 11.6479 45 12.5001V23.2144C45 24.0672 45.3386 24.8858 45.9429 25.4858L54.5143 34.0572C54.9638 34.5062 55.5363 34.8118 56.1594 34.9356C56.7826 35.0594 57.4284 34.9958 58.0154 34.7527C58.6024 34.5097 59.1042 34.0981 59.4574 33.5701C59.8106 33.042 59.9994 32.4211 60 31.7858V3.92864Z" fill="#323232"/>
            </svg>
        `;

        for (const feature of geojson.features) {
            const el = document.createElement('div');
            el.className = 'cameraMarker';
            el.innerHTML = cameraIconSvg;

            // Style the marker element
            el.style.backgroundSize = 'contain';
            el.style.backgroundRepeat = 'no-repeat';
            el.style.cursor = 'pointer';
            el.style.width = '24px'; // Default width, will be adjusted on zoom
            el.style.height = '24px'; // Default height, will be adjusted on zoom

            const popupContent = `
                <div style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    height: fit;
                    width: fit;
                    background-color: rgba(255, 255, 255, 0.4);
                    backdrop-filter: blur(8px);
                    border: none;
                    color: #323232;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
                    padding: 1rem;
                    border-radius: 1.5rem;
                    z-index: 50;
                    transition: all 0.05s;
                ">
                    <h3 style="margin-bottom: 5px;"><strong>${feature.properties.title}</strong></h3>
                    <p style="margin: 0;"><strong>Direction:</strong> ${feature.properties.description.charAt(0).toUpperCase() + feature.properties.description.slice(1)}</p>
                    <p style="margin: 0;"><strong>Lot Number:</strong> ${feature.properties.lotNum}</p>
                    <p style="margin: 0;"><strong>Resolution:</strong> ${feature.properties.resolution.width}x${feature.properties.resolution.height}</p>
                    <button 
                        id="camera-button-${feature.properties.lotNum.replace(/\s+/g, '')}"
                        style="
                            margin-top: 10px; 
                            padding: 5px 10px; 
                            background-color: #9C9C9C; 
                            color: white; 
                            border: none; 
                            border-radius: 4px; 
                            cursor: pointer;
                            font-weight: bold;
                            z-index: 60;
                        "
                    >
                        Open Spot Editor
                    </button>
                </div>`;

            const marker = new mapboxgl.Marker(el)
                .setLngLat(feature.geometry.coordinates)
                .setPopup(
                    new mapboxgl.Popup({
                        offset: 25,
                        closeButton: false,
                        closeOnClick: true,
                        className: 'custom-popup' // Add a custom class to target
                    })
                        .setHTML(popupContent)
                )
                .addTo(map);

            // Rotate the SVG based on direction
            const directionMap = {
                'east': 0,
                'southeast': 45,
                'south': 90,
                'southwest': 135,
                'west': 180,
                'northwest': -135,
                'north': -90,
                'northeast': -45
            };
            const rotation = directionMap[feature.properties.description.toLowerCase()] || 0;
            el.querySelector('svg').style.transform = `rotate(${rotation}deg)`;

            // Add event listener after adding the popup
            marker.getPopup().on('open', () => {
                const button = document.getElementById(`camera-button-${feature.properties.lotNum.replace(/\s+/g, '')}`);
                if (button) {
                    button.addEventListener('click', () => openSpotEditor(feature.properties.title));
                }
            });

            // Update marker size on zoom change
            const baseSize = 18;
            map.on('zoom', () => {
                const zoomLevel = map.getZoom();
                const newSize = baseSize * Math.pow(2, (zoomLevel - baseZoom)); // Adjust size multiplier
                el.style.width = `${newSize}px`;
                el.style.height = `${newSize}px`;
                // Adjust SVG dimensions directly
                el.querySelector('svg').setAttribute('width', newSize);
                el.querySelector('svg').setAttribute('height', newSize);
            });

            // Set initial marker size
            const initialSize = baseSize * Math.pow(2, (map.getZoom() - baseZoom));
            el.style.width = `${initialSize}px`;
            el.style.height = `${initialSize}px`;
            // Adjust SVG dimensions directly
            el.querySelector('svg').setAttribute('width', initialSize);
            el.querySelector('svg').setAttribute('height', initialSize);
        }

        // Optional: Apply additional styles to the custom popup to ensure the default style doesn't interfere
        // Create a new <style> element
        const style = document.createElement("style");

        // Append the <style> element to the <head> section
        document.head.appendChild(style);

        // Insert the CSS rule into the newly created stylesheet
        style.sheet.insertRule(`
            .mapboxgl-popup-content.custom-popup {
                background: transparent !important;
                box-shadow: none !important;
                padding: 0 !important;
                border-radius: 0 !important;
            }
        `, 0);
    };

    function jsonBuilder(arr) {
        var finalJson = []

        arr.forEach((lot) => {
            for (let i in lot) {
                const level = lot[i].level
                const lines = lot[i].lines
                if (currentLevel.toString() === level) {
                    for (let i in lines) {
                        finalJson.push({
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "coordinates": lines[i],
                                "type": "LineString"
                            }
                        })
                    }
                }
            }
        })

        return finalJson
    }

    // Snag line data from the session storage
    const cordInput = JSON.parse(sessionStorage.getItem("lineData"));

    //Add lines to the map. Gets its data from the jsonBuilder function 
    const addLines = async (map) => {
        // Remove existing layer if it exists
        if (map.getLayer('route')) {
            map.removeLayer('route');
        }

        // Remove existing source if it exists
        if (map.getSource('route')) {
            map.removeSource('route');
        }

        // Add the new source
        map.addSource('route', {
            'type': 'geojson',
            'data': {
                "type": "FeatureCollection",
                "features": jsonBuilder(cordInput)
            }
        });

        // Add the line layer
        map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'square',
                'line-sort-key': 10
            },
            'paint': {
                'line-color': '#ffe374',
                'line-width': {
                    'type': 'exponential',
                    'base': 2,
                    'stops': [
                        [0, baseWidth * Math.pow(2, (0 - baseZoom))],
                        [24, baseWidth * Math.pow(2, (24 - baseZoom))]
                    ]
                }
            }
        });
    };

    // Zoom Controls
    const handleZoomIn = () => {
        if (map) {
            map.zoomIn();
        }
    };

    const handleZoomOut = () => {
        if (map) {
            map.zoomOut();
        }
    };

    const closeEditor = async (type, dataToSave) => {
        setEditorOpen(false);

        console.log(type, dataToSave)

        if (type !== "error") {
            if (dataToSave.length > 0) {
                // Save to database
                setEditorOpen(false);
                let totalSaved = 0;
                const loadingToast = toast.loading(`Saving: ${totalSaved}/${dataToSave.length}`);
                let success = false;
                for (let i in dataToSave) {
                    const allViolations = JSON.parse(sessionStorage.getItem("violationData"));
                    try {
                        const currentSpot = allViolations.filter((spot) => spot.spotID === dataToSave[i].spotID)[0];
                        currentSpot.permitType = dataToSave[i].permitType;

                        await postData(`/${orgID}/spots`, currentSpot);

                        totalSaved++;
                        success = true;
                        toast.loading(`Saving: ${totalSaved}/${dataToSave.length}`, { id: loadingToast });
                    } catch (err) {
                        console.error("Error saving to database:", err);
                        toast.error("Error saving to database: " + err);
                        return;
                    }
                }

                success === true && toast.success("Changes saved successfully", { id: loadingToast });
            } else {
                toast.info("No changes to save.");
            }
        }
    }

    const Diamond = ({ fill, level }) => {
        const [hovered, setHovered] = useState(false);

        return (
            <div className="absolute group cursor-pointer">
                {/* Tooltip */}
                {hovered && (
                    <p
                        className={`absolute ${level === 0 ? "right-[-185%]" : "right-[-120%]"} top-1/2 transform -translate-y-1/2 bg-spotGray p-2 font-bold text-sm rounded-xl rotate-180`}
                    >
                        {level === 0 ? "Ground Level" : `Level ${level + 1}`}
                    </p>
                )}
                {/* Diamond */}
                <svg
                    width="1861"
                    height="976"
                    viewBox="0 0 1861 976"
                    className={`w-16 h-auto`}
                    xmlns="http://www.w3.org/2000/svg"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={{
                        fill: hovered ? fill ? "#323232" : "#d1d5db" : fill ? "#323232" : "white",
                        transition: "fill 0.3s ease",
                    }}
                >
                    <path
                        d="M965.063 63.3257L1753.23 409.251C1821.82 439.352 1821.82 536.648 1753.23 566.749L965.063 912.674C943.035 922.342 917.965 922.342 895.937 912.674L107.766 566.749C39.1833 536.648 39.1832 439.352 107.766 409.251L895.937 63.3257C917.965 53.6578 943.035 53.6578 965.063 63.3257Z"
                        stroke="#323232"
                        strokeWidth="90"
                    />
                </svg>
            </div>
        );
    };

    const changeLevel = (index) => {
        const newLevelStates = { ...levelStates };
        Object.keys(newLevelStates).forEach((key) => {
            newLevelStates[key] = false;
        });
        newLevelStates[index] = !newLevelStates[index];
        setCurrentLevel(index);
        setLevelStates(newLevelStates);
    }

    // Display map with or without buttons
    if (buttons === false) {
        return (
            <div id="map-container" style={mapCss}>
                {!isMapReady && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div id="map-container" style={mapCss}>
                {/* Remove styling for original popup */}
                <style>
                    {`
                    .mapboxgl-popup {
                        background: none !important;
                        box-shadow: none !important;
                        border: none !important;
                    }

                    .mapboxgl-popup-content {
                        background: none !important;
                        box-shadow: none !important;
                        border: none !important;
                    }
                `}
                </style>
                {/* <RefreshButton darkMode={darkMode} onClick={() => map.resize()} /> */}
                {!isMapReady && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                )}
                <div className={`scale-${selectedViolation ? '100' : '0'}`}>
                    {selectedViolation && (
                        <div id="titleCard">
                            <TitleCard violation={selectedViolation} onClose={handleCloseViolationCard} />
                        </div>
                    )}
                </div>
                <div className="flex flex-col absolute bottom-4 right-2 z-20 gap-2 text-2xl text-white">
                    <div className="ml-16 fcc gap-3 rotate-180 hover:gap-9 transition-all duration-500 ease-in-out">
                        {Object.entries(levelStates).map(([key, fill], index) => (
                            <div onClick={() => changeLevel(index)} key={index}>
                                <Diamond fill={fill} level={index} />
                            </div>
                        ))}
                    </div>
                    <button className="w-20 bg-spotGray fcc pb-1 rounded-md hover:bg-gray-600  active:bg-gray-500" onClick={handleZoomIn}>+</button>
                    <button className="w-20 bg-spotGray fcc pb-1 rounded-md hover:bg-gray-600 active:bg-gray-500" onClick={handleZoomOut}>-</button>
                </div>
                {editorOpen ? (
                    <div className="absolute fcc items-start w-full h-full top-0 bottom-0 left-0 right-0 z-[3000] bg-black/40">
                        <SpotEditor
                            image={lotImage}
                            closeEditor={closeEditor}
                            camera={activeCamera}
                        />
                    </div>
                ) : null}
                <Toaster richColors />
            </div>
        );
    }
};

// const availableSpotTypes = ["Permit", "ADA", "Visitor", "Reserved"]; //TODO this needs to be dynamic from the orgTable

export default Map;