import React, { useState } from "react";
import { Toaster, toast } from "sonner";
import { SpotEditor } from "./SpotEditor";
import { postData } from "../utility/fetcher";

export const Cameras = () => {
    const [editorOpen, setEditorOpen] = useState(false);
    const [activeCamera, setActiveCamera] = useState({});
    const activeLot = JSON.parse(sessionStorage.getItem('activeLot'));
    const cameras = activeLot.cameras;
    const orgID = JSON.parse(localStorage.getItem("orgData")).orgID;

    const openEditor = (index) => {
        setActiveCamera(cameras[index]);
        setEditorOpen(true);
    }

    const closeEditor = async (type, dataToSave) => {
        setEditorOpen(false);

        if (type !== "error") {
            // Save to database
            setEditorOpen(false);
            for (let i in dataToSave) {
                const allViolations = JSON.parse(sessionStorage.getItem("violationData"));
                const currentSpot = allViolations.filter((spot) => spot.spotID === dataToSave[i].spotID)[0];
                currentSpot.permitType = dataToSave[i].permitType;
                currentSpot.cameras = dataToSave[i].cameras;

                try {
                    console.log("Changes to save to database:", currentSpot);
                    const path = `/${orgID}/spots`
                    const response = await postData(path, currentSpot);
                    console.log(response)
                    toast.success("Saved successfully.");
                } catch (error) {
                    console.error("Error saving to database:", error);
                    toast.error("Error saving to database.");
                    return;
                }
            }
        }
    }

    return (
        <div className="w-full pl-20 fcc justify-start">
            <h1 className="text-3xl font-bold my-8">Cameras</h1>
            <div className="grid grid-cols-3 gap-4">
                {cameras.map((camera, index) => (
                    <div key={index} className="fcc cursor-pointer shadow-md rounded-lg border px-4 py-1 text-spotGray text-2xl hover:bg-gray-100 active:bg-gray-200 transition-all duration-100 ease-in-out" onClick={() => openEditor(index)}>
                        {camera.name}
                    </div>
                ))}
            </div>
            {editorOpen ? (
                <div className="absolute fcc items-start w-screen h-screen top-0 bottom-0 left-0 right-0 z-[1000] bg-black/40">
                    <SpotEditor
                        camera={activeCamera}
                        availableSpotTypes={availableSpotTypes}
                        closeEditor={closeEditor}
                    />
                </div>
            ) : null}
            <Toaster richColors />
        </div>
    )
}

const availableSpotTypes = ["Permit", "ADA", "Visitor", "Reserved"];