import React, { useState } from 'react';
import { toast } from 'sonner';
import "./settings.css";
import { postData } from '../utility/fetcher';

function formatTitle(title) {
    const formattedValue = title
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize each word

    return formattedValue;
}

export const SingleSetting = (title, defaultInput) => {
    const [settingValue, setSettingValue] = useState(defaultInput);
    const [alertValue, setAlertValue] = useState("");
    const [saveText, setSaveText] = useState("Save");
    const formattedTitle = formatTitle(title);

    // Send data to the organization table and mark it as saved
    const handleSave = async () => {
        setAlertValue("");
        setSaveText("Saving...");
        sessionStorage.setItem(title, settingValue);
        const orgData = JSON.parse(localStorage.getItem("orgData"));

        //Update the setting that was changed
        const newData = orgData
        newData[title] = settingValue;

        const path = "/general/organizations"
        const response = await postData(path, newData);
        console.log(response.message);

        toast.success(formattedTitle + " has been saved!");
        setSaveText("Saved");

        setTimeout(() => {
            setSaveText("Save");
        }, 2000);
    };

    return (
        <div key={title} className='flex flex-row justify-between gap-2 h-auto w-full bg-white shadow-md border rounded-lg items-center my-2 p-2 pl-4 py-4'>
            <h2 className='font-bold text-lg min-w-fit'>{formattedTitle}</h2>
            <div className="frc gap-4">
                <div key={"inputs"} className='flex'>
                    <input
                        value={settingValue}
                        className='border-2 rounded-md p-2 w-full'
                        onChange={(e) => {
                            setSettingValue(e.target.value);
                            setSaveText("Save");
                        }}
                    />
                    <p className='alert-values'>{alertValue}</p>
                </div>
                <button key={title} className='h-fit w-fit px-4 py-1 yellow-gradient font-bold text-lg ml-auto mr-4 rounded-lg shadow-md' onClick={handleSave}>
                    {saveText}
                </button>
            </div>
        </div>
    );
}