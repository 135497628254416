import React, { useState } from "react";
import UserPool from "../utility/UserPool"

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form submitted");

        const attributeList = [
            {
                Name: 'email',
                Value: email
            }
        ];

        UserPool.signUp(email, password, attributeList, null, (err, data) => {
            if (err) {
                console.error(err);
                setErrorMessage(err.message);
            } else {
                window.location.href = "/dashboard";
                sessionStorage.setItem("isLoggedIn", true);
                console.log(data);
            }
        });
    }

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "45px",
        boxShadow: "0 1px 28px 10px rgba(0, 0, 0, 0.1)",
        width: "90%",
        maxWidth: "500px",
        height: "500px",
        margin: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "999",
    };

    const titleStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "-8px",
        color: "#323232"
    };

    const inputStyle = {
        width: "100%",
        maxWidth: "500px",
        height: "40px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        marginBottom: "10px",
        paddingLeft: "10px",
        fontSize: "20px",
        color: "#323232",
    };

    const paragraphStyle = {
        display: "flex",
        justifyContent: "center",
        marginBottom: "0px",
        fontSize: "20px",
        color: "#323232",
        width: "70%",
        textAlign: "center"
    }

    const signUp = () => {
        return (
            <div style={containerStyle}>
                <h1 style={{ color: "#323232", fontSize: "50px", fontWeight: "bold", marginBottom: "0px", marginTop: "-10px" }}>Sign Up</h1>
                <p style={paragraphStyle}>Please enter an email and a password. (at least 6 characters)</p>
                <p style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "15px",
                    fontSize: "12px",
                    color: "#323232",
                    width: "95%",
                    textAlign: "center"
                }}>Once finished, text me to confirm your account: <a style={{ textDecoration: "underline" }} href="tel:+15098285653"> (509) 828-5653</a></p>
                <div style={{ width: "75%" }}>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <div style={titleStyle}>
                                Email
                            </div>
                            <input
                                type="text"
                                value={email}
                                style={inputStyle}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div style={titleStyle}>
                                Password
                            </div>
                            <input
                                type="password"
                                value={password}
                                style={inputStyle}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p style={{ color: "red", fontSize: "16px", marginTop: "5px", marginBottom: "-15px" }}>{errorMessage}</p> : null}
                        <br />
                        <br />
                        <button style={{
                            display: "block",
                            justifyContent: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "175px",
                            height: "50px",
                            borderRadius: "20px",
                            backgroundColor: "#FFE374",
                            fontSize: "32px",
                            fontWeight: "bold",
                            color: "white",
                            cursor: "pointer",
                            border: "transparent",
                            boxShadow: "0px 0px 18px 4px rgba(0, 0, 0, 0.12)",
                            textShadow: "0px 0px 18px rgba(0, 0, 0, 0.12)",
                        }} type="submit">Sign Up</button>
                    </form>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontSize: "12px",
                        color: "#323232"
                    }}>
                        Already have an account? <a style={{ textDecoration: "underline", marginLeft: "5px" }} href="/login"> Login</a>
                    </div>
                </div>
            </div>
        )
    }

    const square = (width, height, x, y, rotation) => {
        return (
            <div style={{
                position: "absolute",
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                backgroundColor: "#D9D9D9",
                transform: `rotate(${rotation}deg)`,
                borderRadius: "45px",
                opacity: "0.2",
                boxShadow: "0 0px 36px 18px rgba(0, 0, 0, 1.45)",
                top: `${y}vh`,
                left: `${x}vw`,
                zIndex: "0",
            }}></div>
        );
    }

    return (
        <div style={{
            background: "linear-gradient(to top, #FFE374, #474747)",
            height: "100vh",
        }}>
            {signUp()}
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "1",
                overflow: "hidden",
            }}>
                {square(50, 50, 20, -25, 40)}
                {square(70, 70, 0, 80, -20)}
                {square(70, 70, 90, 40, -20)}
            </div>
        </div>
    );
}

export default Signup;