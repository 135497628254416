import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { BsPassFill } from "react-icons/bs";
import { fetchAllData, postData } from "../utility/fetcher";
import { isObjDifferent } from "../utility/isObjDifferent";

export const Permits = () => {
    const [tableData, setTableData] = useState([]);
    const [saveMessage, setSaveMessage] = useState("Save");
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [violationData, setViolationData] = useState([]);
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const orgID = orgData.orgID;

    const handleEdit = (index, field, value) => {
        // Update the tableData array with the new value
        const updatedTableData = [...tableData];
        updatedTableData[index][field] = value;
        setTableData(updatedTableData);
        console.log(tableData)
    };

    const handleCheck = (index) => {
        const updatedIndexes = [...checkedIndexes];
        if (updatedIndexes.includes(index)) {
            updatedIndexes.splice(updatedIndexes.indexOf(index), 1);
        } else {
            updatedIndexes.push(index);
        }
        setCheckedIndexes(updatedIndexes);
    };

    const saveButton = () => {
        const handleSaveClick = async () => {
            setSaveMessage("Saving...");
            const toastId = toast.loading("Saving data...");
            const newData = orgData;

            const different = isObjDifferent(newData.permitTypes, tableData);

            if (!different) {
                toast.info("No changes detected.", { id: toastId });
                setSaveMessage("Save");
                return;
            }

            newData.permitTypes = tableData;

            try {
                console.log("Data to save:", newData);
                const path = "/general/organizations";
                const response = await postData(path, newData);
                toast.success("Permit data saved successfully!", { id: toastId });
                console.log(response.message)

                setSaveMessage("Saved");
                setTimeout(() => {
                    setSaveMessage("Save");
                }, 2000);
            } catch (error) {
                console.error("Error saving data:", error);
                toast.error("Permit data failed to save. Please try again.", { id: toastId });
            }

        };

        return (
            <div>
                <button className="block w-[150px] h-8 rounded-lg shadow-md yellow-gradient"
                    onClick={handleSaveClick}
                >
                    {saveMessage}
                </button>
            </div>
        );
    };

    const addButton = () => {
        const addPermit = () => {
            const newUser = {
                description: "",
                permitType: "",
                price: "",
                public: false,
            };
            const updatedTableData = [...tableData];
            updatedTableData.push(newUser);
            setTableData(updatedTableData);
        };

        return (
            <div>
                <button className="block w-[150px] h-8 rounded-lg shadow-md bg-gray-100 hover:bg-gray-200" onClick={addPermit}>
                    Add Permit
                </button>
            </div>
        );
    }

    const removeButton = () => {
        const removeUser = () => {
            const updatedTableData = tableData.filter((_, index) => !checkedIndexes.includes(index));
            setTableData(updatedTableData);

            setSaveMessage("Saving...");
            const newData = orgData;
            newData.permitTypes = updatedTableData;

            console.log("DATA TO DELETE:", newData);
            const path = "/general/organizations";
            const response = postData(path, updatedTableData);
            console.log(response.message);

            setCheckedIndexes([]);
        };

        return (
            <div className="block ml-2 mr-auto">
                <button className="block w-[150px] h-8 rounded-lg shadow-md bg-red-200 hover:bg-red-300" onClick={removeUser}>
                    Remove Permit
                </button>
            </div>
        );
    }

    const storeData = async () => {
        try {
            const pathObj = {
                "orgData": `/general/organizations?orgID=${orgID}`,
                "violationData": `/${orgID}/spots`,
            };
            const response = await fetchAllData(pathObj);
            const permitTypes = response.orgData.permitTypes;
            setTableData(permitTypes);
            setViolationData(response.violationData);
            sessionStorage.setItem("orgData", JSON.stringify(response.orgData));
            sessionStorage.setItem("violationData", JSON.stringify(response.violationData));
            setLoading(false);
        } catch (error) {
            console.error("Error getting data:", error);
            toast.error("Data failed to save. Please try again.");
        }
    }

    useEffect(() => {
        storeData();
        // eslint-disable-next-line
    }, []);

    const allLots = orgData.lots.map((lot) => parseInt(lot.lotNum));
    const allLotsString = allLots.sort().join(", ");

    return (
        <div className="fcc font-rubik">
            <Toaster richColors />
            <h1 className="text-3xl font-bold mt-8">Permits</h1>
            {loading ? <h1 className="p-8">Loading...</h1> :
                <div className="flex flex-col items-center justify-center w-fit m-4 ml-24">
                    <table className="table-auto mx-8">
                        <thead>
                            <tr className="table-auto text-xl">
                                <th>Select</th>
                                <th key="type" className="p-2 text-center w-8">Type</th>
                                <th key="description" className="p-2 text-center w-8">Description</th>
                                <th key="usedAt" className="p-2 text-center w-8">Used At</th>
                                <th key="usedAt" className="p-2 text-center w-8">Plates Per Permit</th>
                                <th key="price" className="p-2 text-center w-8">Price</th>
                                <th key="public" className="p-2 text-center w-8">Public</th>
                            </tr>
                        </thead>
                        <tbody className="my-4">
                            {Array.isArray(tableData) ? tableData.map((row, index) => {
                                let usedAt = [];

                                for (let i in violationData) {
                                    if (violationData[i].permitType === row.permitType) {
                                        if (!usedAt.includes(violationData[i].lot)) {
                                            usedAt.push(violationData[i].lot);
                                        }
                                    }
                                }

                                const usedAtSet = new Set(usedAt);
                                const usedAtString = Array.from(usedAtSet).sort().join(", ");

                                return (
                                    <tr key={`${index}-${row.permitType || ''}`} className="p-2 border-b border-gray-300">
                                        <td className="p-2 text-center w-8">
                                            <input
                                                type="checkbox"
                                                key={"select"}
                                                className="select-check"
                                                onChange={(e) => handleCheck(index)}
                                            />
                                        </td>
                                        <td className="p-2 text-center min-w-10 frc">
                                            <Link
                                                to={`/settings/permits/${row.permitType}`}
                                                key={row.permitType}
                                                onClick={() => sessionStorage.setItem("currentPermit", JSON.stringify(row))}
                                                className="hover:cursor-pointer"
                                            >
                                                <BsPassFill size={20} className="text-gray-500" />
                                            </Link>
                                            <input
                                                type="text"
                                                key={"permitType"}
                                                value={row.permitType}
                                                className="text-left ml-4 px-2 w-16 outline-spotYellow"
                                                onChange={(e) => handleEdit(index, "permitType", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center min-w-12 grow">
                                            <input
                                                type="text"
                                                key={"description"}
                                                value={row.description}
                                                className="w-full text-center outline-spotYellow"
                                                onChange={(e) => handleEdit(index, "description", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center min-w-12 w-36 grow">
                                            {usedAtString === allLotsString ? "All" : usedAtString}
                                        </td>
                                        <td className="p-2 text-center min-w-12 grow">
                                            <input
                                                type="text"
                                                key={"platesPerPermit"}
                                                value={row.platesPerPermit}
                                                className="w-48 text-center outline-spotYellow"
                                                onChange={(e) => handleEdit(index, "platesPerPermit", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center min-w-12 grow">
                                            <input
                                                type="text"
                                                key={"price"}
                                                value={row.price}
                                                className="w-48 text-center outline-spotYellow"
                                                onChange={(e) => handleEdit(index, "price", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center min-w-12 grow">
                                            <input
                                                type="checkbox"
                                                key={"public"}
                                                className="select-check"
                                                checked={row.public}
                                                onChange={(e) => handleEdit(index, "public", e.target.checked)}
                                            />
                                        </td>
                                    </tr>
                                );
                            }) : null}
                        </tbody>
                    </table>
                    <div className="flex flex-row mt-6 mx-4 font-bold w-full justify-between">
                        {addButton()}
                        {removeButton()}
                        {saveButton()}
                    </div>
                </div>
            }
        </div >
    );
};