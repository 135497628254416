import React from "react";

const Loader = () => {
    return (
        <div className="flex flex-row items-center justify-center h-screen w-screen dark:bg-spotGray">
            <div className="absolute pr-48 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 dark:bg-spotGray">
                <div className="absolute text-[120px] top-[-110px] ml-3 font-bold text-spotYellow text-center px-5 typewriter font-rubik animate-typing">SPOT</div>
                <div className="absolute top-[-105px] h-5 yellow-gradient rounded-md animate-grow-line-1"></div>
                <div className="absolute top-[45px] h-5 yellow-gradient rounded-md animate-grow-line-2"></div>
                <div className="absolute top-[-105px] w-5 bg-spotYellow rounded-md animate-grow-line-3"></div>
            </div>
        </div>
    );
}

export default Loader;