import React from "react";

const TimeParked = ({ dateTime }) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true, timeZone: "UTC" };
    const timeString = new Date(dateTime).toLocaleTimeString([], options);

    return <span>{timeString}</span>;
};

export default TimeParked;
