import React, { useState, useEffect } from "react";
import { Toaster, toast } from "sonner";
import Loader from "../utility/Loader";
import { fetchAllData, postData } from "../utility/fetcher";

export const Announcements = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [saveMessage, setSaveMessage] = useState("Save");
    // const [removeButton, setRemoveButton] = useState("Remove");
    // const [checkedIndexes, setCheckedIndexes] = useState([]);
    const user = "Ryan"; // TODO: Change to authenticated user info
    sessionStorage.setItem("user", user);

    const storeData = async () => {
        try {
            const url = { "announcements": "/general/announcements" };
            const data = await fetchAllData(url);
            console.log(data)
            setData(data.announcements);
            sessionStorage.setItem("announcements", JSON.stringify(data));
            setLoading(false);
        } catch (error) {
            console.error("Error storing data:", error);
        }
    };

    const handleEdit = (index, field, value) => {
        // Update the tableData array with the new value for each key
        const updatedData = structuredClone(data);

        if (field === 'public' || field === 'permit' || field === 'enforcement') {
            const currentStatus = updatedData[index].recipients[field];
            const newStatus = !currentStatus;
            updatedData[index].recipients[field] = newStatus;
        } if (field === "active") {
            updatedData[index][field] = !updatedData[index][field];
        } else {
            updatedData[index][field] = value;
        }
        setData(updatedData);
    };

    // const handleCheck = (index) => { //TODO WHY THE FUCK IS THIS NOT WORKING
    //     const updatedIndexes = [...checkedIndexes];
    //     if (updatedIndexes.includes(index)) {
    //         updatedIndexes.splice(updatedIndexes.indexOf(index), 1);
    //     } else {
    //         updatedIndexes.push(index);
    //     }
    //     console.log("Updated indexes:", updatedIndexes);
    //     setCheckedIndexes(updatedIndexes);
    // };

    // const handleRemove = () => {
    //     const updatedData = data.filter((_, index) => !checkedIndexes.includes(index));
    //     setData(updatedData);
    //     setCheckedIndexes([]); // Clear selected indexes after removal
    // };

    function formatDateTimeForInput(dateTimeString) {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const parseDateInput = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date) ? dateString : date.toISOString();
    };

    const handleSave = async () => {
        for (let i in data) {
            Object.keys(data[i]).forEach((key) => {
                if (data[i][key] === "") {
                    const currentField = key;
                    toast.error(`Please fill out ${currentField} before saving.`);
                    return;
                }
            });
        }

        //TODO Make this so that it only saves ones that have been changed

        let totalSaved = 0;
        const loadingToast = toast.loading(`Saving: ${totalSaved}/${data.length}`);
        setSaveMessage("Saving...");
        try {
            for (let i in data) {
                const path = "/general/announcements";
                const response = await postData(path, data[i])
                console.log(response.message)
                totalSaved++;
                toast.loading(`Saving: ${totalSaved}/${data.length}`, { id: loadingToast });
            }

            toast.success("Data has been saved!", { id: loadingToast });
            setSaveMessage("Saved");
            setTimeout(() => {
                setSaveMessage("Save");
            }, 2000);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const addButton = () => {
        const addNewAnnouncement = () => {
            const newAnnouncement = {
                active: false,
                title: "",
                body: "",
                expirationDate: new Date().toISOString(),
                recipients: {
                    public: false,
                    permit: false,
                    enforcement: false,
                },
                creationDate: new Date().toISOString(),
                organizationID: "000001", //TODO: Change to authenticated user's organization ID
                author: sessionStorage.getItem("user")
            };

            const updatedData = structuredClone(data);
            updatedData.push(newAnnouncement);
            setData(updatedData);
        };

        return (
            <div>
                <button
                    className="block w-fit px-2 text-md h-8 rounded-lg shadow-md bg-gray-100 hover:bg-gray-200"
                    onClick={addNewAnnouncement}
                >
                    Add Announcement
                </button>
            </div>
        );
    };

    useEffect(() => {
        storeData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex flex-col font-rubik">
            <Toaster richColors />
            <div className="flex flex-row flex-wrap justify-center gap-8 w-[100%] mt-10 pl-24 pr-4">
                {loading ? (
                    <div className="flex flex-row items-center justify-center h-screen w-screen">
                        <Loader />
                    </div>
                ) : (
                    <div className="flex flex-col items-center p-8 w-full">
                        <h1 className="text-3xl font-bold text-spotGray mb-4">Announcements</h1>
                        <table className="table-auto mb-0 w-full">
                            <thead className="pb-6">
                                <tr>
                                    {/* <th className="border text-center">Select</th> */}
                                    {/* <th className="border text-center">Select</th> */}
                                    <th className="border text-center">Active</th>
                                    <th className="border">Title</th>
                                    <th className="border">Announcement Body</th>
                                    <th className="border">Recipients</th>
                                    <th className="border">Expiration</th>
                                </tr>
                            </thead>
                            <tbody className="text-center w-full">
                                {data.map((announcement, index) => (
                                    <tr key={index}>
                                        {/* <td className="border px-4 py-2 flex-none w-16 transition-all duration-0 font-bold hover:bg-gray-100">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleCheck(index)}
                                                className="w-5 h-5 accent-spotYellow rounded-sm"
                                            />
                                        </td> */}
                                        <td className="border px-4 py-2 flex-none w-16 transition-all duration-0 font-bold hover:bg-gray-100">
                                            <input
                                                type="checkbox"
                                                name="active"
                                                checked={announcement.active}
                                                onChange={() => handleEdit(index, 'active', !announcement.active)}
                                                className="w-5 h-5 accent-spotYellow rounded-sm"
                                            />
                                        </td>
                                        <td className="border px-4 py-2 flex-1 max-w-28 transition-all duration-0 hover:bg-gray-100">
                                            <input
                                                type="text"
                                                name="title"
                                                value={announcement.title}
                                                onChange={(e) => handleEdit(index, 'title', e.target.value)}
                                                className="w-full px-2 py-1 border rounded outline-spotYellow"
                                            />
                                        </td>
                                        <td className="border px-4 py-2 flex-1 transition-all duration-0 hover:bg-gray-100">
                                            <input
                                                type="text"
                                                name="body"
                                                value={announcement.body}
                                                onChange={(e) => handleEdit(index, 'body', e.target.value)}
                                                className="w-full px-2 py-1 border rounded outline-spotYellow"
                                            />
                                        </td>
                                        <td className="border px-4 py-2 flex-none w-48 transition-all duration-0 hover:bg-gray-100">
                                            <div className="frc gap-2">
                                                <label htmlFor="recipients">Public</label>
                                                <input
                                                    type="checkbox"
                                                    name="public"
                                                    checked={announcement.recipients.public}
                                                    onChange={(e) => handleEdit(index, 'public', e.target.value)}
                                                    className="px-2 py-1 border rounded cursor-pointer accent-spotYellow outline-spotYellow"
                                                />
                                            </div>
                                            <div className="frc gap-2">
                                                <label htmlFor="recipients">Permit Holders</label>
                                                <input
                                                    type="checkbox"
                                                    name="permit"
                                                    checked={announcement.recipients.permit}
                                                    onChange={(e) => handleEdit(index, 'permit', e.target.value)}
                                                    className="px-2 py-1 border rounded cursor-pointer accent-spotYellow outline-spotYellow"
                                                />
                                            </div>
                                            <div className="frc gap-2">
                                                <label htmlFor="recipients">Enforcement</label>
                                                <input
                                                    type="checkbox"
                                                    name="enforcement"
                                                    checked={announcement.recipients.enforcement}
                                                    onChange={(e) => handleEdit(index, 'enforcement', e.target.value)}
                                                    className="px-2 py-1 border rounded cursor-pointer accent-spotYellow outline-spotYellow"
                                                />
                                            </div>
                                        </td>
                                        <td className="border px-4 py-2 flex-1 max-w-32 transition-all duration-0 hover:bg-gray-100">
                                            <input
                                                type="datetime-local"
                                                name="expirationDate"
                                                value={announcement.expirationDate ? formatDateTimeForInput(announcement.expirationDate) : ""}
                                                onChange={(e) => handleEdit(index, 'expirationDate', parseDateInput(e.target.value))}
                                                className="w-full px-2 py-1 border rounded outline-spotYellow"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="flex flex-row justify-between mt-6 mx-4 font-bold w-full">
                            <div className="frc gap-4">
                                {addButton()}
                                {/* <div className={`fcc w-fit cursor-pointer px-4 py-1 text-md font-bold text-center h-fit rounded-lg shadow-md bg-red-200 ${checkedIndexes.length = 0 && "bg-green-200"}`} onClick={handleRemove}>
                                    {removeButton}
                                </div> */}
                            </div>
                            <div className="fcc w-fit cursor-pointer px-4 py-1 text-md font-bold text-center h-fit rounded-lg shadow-md yellow-gradient" onClick={handleSave}>
                                {saveMessage}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
