import React from 'react';
import { timeSince } from '../../utility/timeHelper';
import { FaFileCircleXmark, FaRectangleXmark, FaFaceMeh, FaHourglassEnd } from 'react-icons/fa6';

const ListItem = ({
    licensePlate,
    violationType,
    lot,
    spot,
    timeParked,
    coordinates,
    vehicleType,
    permitNumber
}) => {
    // Destructure from argument
    const itemData = {
        licensePlate,
        violationType,
        lot,
        spot,
        timeParked,
        coordinates,
        vehicleType,
        permitNumber
    };

    // Icon selection
    const handleClick = () => {
        sessionStorage.setItem('itemData', JSON.stringify(itemData));
    };

    const iconFinder = (violationType) => {
        switch (violationType) {
            case 'Expired Meter':
                return <FaHourglassEnd size={24} />;
            case 'No Pass':
                return <FaFileCircleXmark size={24} />;
            case 'Wrong lot':
                return <FaRectangleXmark size={24} />;
            default:
                return <FaFaceMeh size={24} />;
        }
    }

    return (
        <div onClick={handleClick} className='cursor-pointer'>
            <div className="ml-24 mr-4 my-4">
                <div className="w-full flex flex-row justify-between h-20 rounded-xl bg-white border shadow-md hover:bg-gray-100">
                    <div className="flex flex-col justify-between p-4">
                        <div className='font-bold text-lg'>
                            <span className="">Plate #: {licensePlate}</span>
                            <span className="ml-4 font-medium">Violation: {violationType}</span>
                        </div>
                        <div>
                            <span className="">spot: {lot}-{spot}</span>
                            <span className="mx-2">-</span>
                            <span className="">
                                Parked for: {timeSince(timeParked)}
                            </span>
                        </div>
                    </div>
                    <div className='flex p-4 pt-7'>
                        {iconFinder(violationType)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListItem;