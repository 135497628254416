import React from "react";
import { copyToClipboard } from "./copyToClipboard";
import { Toaster } from "sonner";
import { LuCopy } from "react-icons/lu";

export const CarTile = ({ vehicle }) => {
    return (
        <div className="flex flex-col mt-4 border min-w-fit border-gray-100 rounded-xl shadow-md p-8 w-[80%]">
            <h3 className="text-2xl w-fit font-semibold mb-1">{vehicle.make} {vehicle.model}</h3>
            <div className="flex flex-row justify-start gap-2 items-center cursor-pointer mb-1" onClick={() => copyToClipboard(vehicle.licensePlate)}>
                <h2 className="text-lg w-fit"><strong className="cursor-default">Plate:</strong> {vehicle.licensePlate}</h2>
                <LuCopy size={15} className="text-gray-500" />
            </div>
            <h2 className="text-lg w-fit"><strong className="cursor-default mb-1">Color:</strong> {vehicle.color}</h2>
            <h2 className="text-lg w-fit"><strong className="cursor-default mb-1">Type:</strong> {vehicle.type}</h2>
            <div className="flex flex-row justify-start gap-2 items-center cursor-pointer mb-1" onClick={() => copyToClipboard(vehicle.vin)}>
                <h2 className="text-sm w-fit font-mono"><strong className="cursor-default">VIN:</strong> {vehicle.vin}</h2>
                <LuCopy size={15} className="text-gray-500" />
            </div>
            <Toaster />
        </div>
    );
}