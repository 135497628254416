// Ex Input: 2021-08-01T12:00:00Z
export const formatTimeFromUTC = (utcTimeStr) => {
    // Create a Date object from the UTC time string
    const date = new Date(utcTimeStr);

    // Extract the hours and minutes in local time
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();

    // Determine AM or PM
    const ampm = hour >= 12 ? 'PM' : 'AM';

    // Format the hour
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

    // Format the minute with leading zero if needed
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    // Return the formatted time
    return `${formattedHour}:${formattedMinute} ${ampm}`;
}

// Ex Input: 2021-08-01T12:00:00Z
export const timeSince = (pastTimeStr) => {
    // Create Date object from the past time string
    const pastDate = new Date(pastTimeStr);
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffMs = now - pastDate;

    // Convert the difference to minutes
    const diffMins = Math.floor(diffMs / (1000 * 60));
    const diffHrs = Math.floor(diffMins / 60);
    const remainingMins = diffMins % 60;

    // Build the output string
    let result = '';
    if (diffHrs > 0) {
        result += `${diffHrs} hr${diffHrs > 1 ? 's' : ''}`;
    }
    if (remainingMins > 0) {
        if (diffHrs > 0) {
            result += ' ';
        }
        result += `${remainingMins} min${remainingMins > 1 ? 's' : ''}`;
    }
    if (result === '') {
        result = '0 min';
    }

    return result;
}