import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../utility/UserPool";
import Cookies from "js-cookie";
import { fetchAllData } from "../utility/fetcher";
import "./login.css";

const Login = () => {
    const [organization, setOrganization] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [cover, setCover] = useState(false)
    const [signInOrgs, setSignInOrgs] = useState([]);
    const [buttonMessage, setButtonMessage] = useState("Login");
    const jsCookie = Cookies.get("accessToken");

    const storeData = async () => {
        try {
            const result = await fetchAllData({ "signInOrgs": "/public/getOrganizations" });
            setSignInOrgs(result.signInOrgs.organizations);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (organization === "") {
            setErrorMessage("Please select an organization.");
            return;
        } else if (email === "") {
            setErrorMessage("Please enter an email.");
            return;
        } else if (password === "") {
            setErrorMessage("Please enter a password.");
            return;
        }

        setButtonMessage("Logging in...");
        const tempEmail = email.trim().toLowerCase();
        const user = new CognitoUser({
            Username: tempEmail,
            Pool: UserPool
        });

        const authDetail = new AuthenticationDetails({
            Username: tempEmail,
            Password: password.trim()
        });

        user.authenticateUser(authDetail, { //TODO This is not working and I have literally no idea why
            onSuccess: async (data) => {
                console.log("Success:", data);

                const jwt = data.getAccessToken().getJwtToken()
                const cognitoUser = UserPool.getCurrentUser();

                cognitoUser.getSession((err, session) => {
                    if (err) {
                        console.log(err);
                        return;
                    }

                    cognitoUser.getUserAttributes((err, attributes) => {
                        if (err) {
                            console.log(err);
                            return;
                        }

                        const username = cognitoUser.getUsername();
                        localStorage.setItem("username", username);
                        localStorage.setItem("userData", JSON.stringify(attributes));
                        for (let i in attributes) {
                            localStorage.setItem(attributes[i].getName(), attributes[i].getValue());
                        }
                    });
                });

                localStorage.setItem("organization", organization);
                let allowed = false
                const urls = { "auth": `/public/validateUser?email=${email.trim().toLowerCase()}&orgName=${organization}&platform=admin` };
                const response = await fetchAllData(urls);
                allowed = response.auth;

                if (allowed) {
                    console.log("User allowed to access application.");
                    setCover(true)

                    setTimeout(() => {
                        localStorage.setItem('jwt', jwt);
                        Cookies.set("accessToken", jwt, { expires: 7 });
                        window.location.href = "/";
                    }, 2000);
                } else {
                    setErrorMessage("User not allowed to access this application.");
                    setButtonMessage("Login");
                }
            },
            onFailure: (err) => {
                setErrorMessage(err.message);
                setButtonMessage("Login");
            },
            newPasswordRequired: (data) => {
                window.location.href = "/reset-password";
                console.log("New password required:", data);
            }
        });
    }

    const demoSubmit = () => {
        setButtonMessage("Logging in...");
        setErrorMessage("");
        setEmail("demo@spotparking.app");
        setPassword("spotparking");
        setOrganization("Demo");
        // These two chunks are stressful, maybe we shouldn't touch them
        const user = new CognitoUser({
            Username: "demo@spotparking.app",
            Pool: UserPool
        });

        const authDetail = new AuthenticationDetails({
            Username: "demo@spotparking.app",
            Password: "spotparking"
        });

        // Authenticate the user
        user.authenticateUser(authDetail, {
            onSuccess: (data) => {

                const cognitoUser = UserPool.getCurrentUser();

                cognitoUser.getSession((err, session) => {
                    if (err) {
                        console.log(err);
                        return;
                    }

                    cognitoUser.getUserAttributes((err, attributes) => {
                        if (err) {
                            console.log(err);
                            return;
                        }

                        const username = cognitoUser.getUsername();
                        localStorage.setItem("username", username);
                        localStorage.setItem("userData", JSON.stringify(attributes));
                        localStorage.setItem("organization", "Demo");
                        for (let i in attributes) {
                            localStorage.setItem(attributes[i].getName(), attributes[i].getValue());
                        }
                    });
                });

                let allowed = true;

                if (allowed) {
                    console.log("User allowed to access application.");
                    setCover(true)

                    setTimeout(() => {
                        Cookies.set("accessToken", data.getAccessToken().getJwtToken(), { expires: 7 });
                        window.location.href = "/";
                    }, 2000);
                } else {
                    setErrorMessage("User not allowed to access this application.");
                }
            },
            onFailure: (err) => {
                console.error("Failure:", err);
                setButtonMessage("Login");
                setErrorMessage(err.message);
            },
            newPasswordRequired: (data) => {
                window.location.href = "/reset-password";
                console.log("New password required:", data);
            }
        });
    }

    useEffect(() => {
        storeData();
    }, []);

    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
        return <Navigate to="/" />;
    } else {
        console.log("Authenticated:", false);
        Cookies.remove("accessToken");

        const allowedPaths = ["/login", "/contact-us", "/reset-password"];
        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
            sessionStorage.clear();
            localStorage.clear();
        }
    }

    return (
        <div className="bg-gradient-to-t from-spotYellow to-gray-700 h-screen overflow-hidden relative justify-center flex items-center font-rubik">
            <div className={`flex flex-col justify-center items-center gap-4 ${cover ? "z-0" : "z-40"}`}>
                <div className="w-fit h-fit bg-white rounded-3xl p-8 z-50 text-spotGray justify-center items-center shadow-lg">
                    <h1 className="text-5xl font-bold self-center mb-4">Login</h1>
                    <p className="text-2xl text-center mb-6">Log in using credentials provided by <br />the Spot Parking team.</p>
                    <div className="flex flex-col justify-center items-center">
                        <form onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center">
                            <label className="w-full">
                                <div className="font-bold text-xl">
                                    Organization
                                </div>
                                <select
                                    value={organization}
                                    className="w-full h-[40px] border border-gray-400 outline-spotYellow rounded-lg pl-2 text-spotGray bg-white mb-4"
                                    onChange={(e) => {
                                        setOrganization(e.target.value)
                                        setErrorMessage("")
                                    }}
                                >
                                    <option value="">Select Organization</option>
                                    {signInOrgs.map((org) => (
                                        <option key={org} value={org}>{org}</option>
                                    ))}
                                </select>
                            </label>
                            <label className="w-full">
                                <div className="font-bold text-xl">
                                    Email
                                </div>
                                <input
                                    type="text"
                                    value={email}
                                    className="w-full h-[40px] border border-gray-400 outline-spotYellow rounded-lg pl-2 text-spotGray bg-white mb-4"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setErrorMessage("")
                                    }}
                                />
                            </label>
                            <label className="w-full">
                                <div className="font-bold text-xl">
                                    Password
                                </div>
                                <input
                                    type="password"
                                    value={password}
                                    className="w-full h-[40px] border border-gray-400 outline-spotYellow rounded-lg pl-2 text-spotGray bg-white mb-4"
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                        setErrorMessage("")
                                    }}
                                />
                            </label>
                            {errorMessage !== "" ? <p className="text-red-500 fond-bold mb-2">{errorMessage}</p> : <div className="mb-1"><br /></div>}
                            <div className="w-fit px-8 h-12 fcc cursor-pointer bg-gradient-to-r from-spotYellow to-yellow-200 rounded-2xl font-bold text-2xl transition-all duration-100 hover:bg-gradient-to-r hover:from-white hover:to-white hover:text-spotYellow hover:border-2 hover:border-spotYellow hover:shadow-lg" onClick={handleSubmit}>{buttonMessage}</div>
                        </form>
                        <div className="login-bottom-links flex flex-col text-center">
                            <p>Don't have an account? <span><a className="ml-2 underline" href="/contact-us"> Contact Us</a></span></p>
                            {/* <a className="ml-2 underline" href="/reset-password">Recover Password</a> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between rounded-3xl h-16 w-full bg-white z-50 shadow-lg">
                    <div className="flex flex-row font-bold text-lg text-center self-center ml-4">
                        <span className=" drop-shadow-lg mr-2">👀</span> Looking for the demo?
                    </div>
                    <div className="flex justify-center items-center bg-spotGray cursor-pointer text-white rounded-2xl w-30 mr-2 h-12 px-2 self-center font-bold text-xl transition-all duration-200 hover:bg-spotYellow hover:text-spotGray hover:shadow-lg" onClick={demoSubmit}>
                        Click Here
                    </div>
                </div>
            </div>
            <div className="absolute w-screen h-screen z-10 overflow-hidden">
                <div className="bg-gray-200/20 rounded-3xl shadow-sm transition-all animate-floatMultiDirection1 absolute w-[70%] h-[50%] top-[70%] left-[70%]" ></div>
                <div className="bg-gray-200/20 rounded-3xl shadow-sm transition-all animate-floatMultiDirection2 absolute w-[40%] h-[50%] top-[70%] -left-[10%] rotate-45" ></div>
                <div className="bg-gray-200/20 rounded-3xl shadow-sm transition-all animate-floatMultiDirection3 absolute w-[50%] h-[45%] -top-[15%] left-[35%]" ></div>
            </div>
            <div className={`absolute top-0 left-0 right-0 bottom-0 transition-all duration-[2s] bg-white ${cover ? "opacity-100 z-50" : "opacity-0 z-0"}`}></div>
        </div>
    );
}

export default Login;