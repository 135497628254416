import React, { useState, useEffect } from "react";
import { PieChart } from 'react-minimal-pie-chart';
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { fetchAllData } from "../utility/fetcher";
import Loader from "../utility/Loader";

const Dashboard = () => {
    const [orgData, setOrgData] = useState({});
    const [loading, setLoading] = useState(true);
    const orgName = localStorage.getItem("organization");

    const storeData = async () => {
        try {
            // const orgDataRaw = sessionStorage.getItem("orgData");
            // const orgData = orgDataRaw === null ? null : JSON.parse(orgDataRaw);

            const orgResponse = await fetchAllData({ "orgData": `/public/getOrgByName?orgName=${orgName}` });
            const orgData = orgResponse.orgData;
            setOrgData(orgData);
            localStorage.setItem("orgData", JSON.stringify(orgData));
            const orgID = orgData.orgID;
            const urls = {
                "violationData": `/${orgID}/spots`,
                // "violationHistory": `/${orgID}/history`,
                "lineData": `/${orgID}/lines`,
            }

            const finalData = await fetchAllData(urls);

            sessionStorage.setItem("orgData", JSON.stringify(finalData.orgData));
            sessionStorage.setItem("violationData", JSON.stringify(finalData.violationData));
            // sessionStorage.setItem("violationHistory", JSON.stringify(finalData.violationHistory));
            sessionStorage.setItem("lineData", JSON.stringify(finalData.lineData));

            const lots = orgData.lots
            let cameraArray = []
            for (let i in lots) {
                const lot = lots[i]
                const lotCameras = lot.cameras
                for (let j in lotCameras) {
                    cameraArray.push(lotCameras[j])
                }
            }

            sessionStorage.setItem("cameraArray", JSON.stringify(cameraArray));
            setLoading(false);
        } catch (error) {
            console.error("Error storing data:", error);
        }
    };

    // find number of lots
    function findAllLots(lotData) {
        for (let i in lotData) {
            lotNumbers.push(lotData[i].lotNum);
        }

        const uniqueLotNumbers = [...new Set(lotNumbers)].sort();
        return uniqueLotNumbers;
    }

    // For each lot, find total number of violations
    function vehiclesPerLot(lots, violationData) {
        var occupancy = {};
        function singleLotViolations(lot, violationData) {
            for (let i in lot) {
                var countInLot = 0;
                var countInLotPending = 0;
                var countWritten = 0;
                var totalCars = 0;

                occupancy[lot[i]] = 0;
                for (let j in violationData) {
                    if (violationData[j].lot === lot[i]) {
                        occupancy[lot[i]]++;

                        violationData[j].licensePlate !== "" && totalCars++;

                        if (violationData[j].ticketWritten === false && violationData[j].violationType !== "") {
                            countInLotPending++;
                        } else if (violationData[j].ticketWritten === true) {
                            countWritten++;
                        }
                    }
                }
                countInLot = countInLotPending + countWritten;
            }

            return [countInLot, countInLotPending, countWritten, totalCars, occupancy];
        }

        var violationsPerLot = [];
        var ticketsPendingPerLot = [];
        var writtenPerLot = [];
        var totalCarsPerLot = [];
        var occupancyPerLot = [];
        for (let i in lots) {
            const violationCount = singleLotViolations(lots[i], violationData);
            violationsPerLot.push(violationCount[0]);
            ticketsPendingPerLot.push(violationCount[1]);
            writtenPerLot.push(violationCount[2]);
            totalCarsPerLot.push(violationCount[3]);
            occupancyPerLot.push(violationCount[4]);
        }

        return [violationsPerLot, ticketsPendingPerLot, writtenPerLot, totalCarsPerLot, occupancyPerLot];
    }

    // Calculate total occupancy
    function getTotalOccupancy(lots) {
        const occupancyPerLot = perLotData[4][0]

        let totalOccupancy = 0;
        for (let i in occupancyPerLot) {
            totalOccupancy += occupancyPerLot[i]
        }

        return totalOccupancy
    }

    // Calculate average occupancy
    function getAverageOccupancy(perLotData) {
        const totalVehiclesPerLot = perLotData[3]
        const occupancyPerLot = perLotData[4][0]
        const occupancyArray = Object.values(occupancyPerLot)

        let totalOccupancy = 0;
        for (let i in totalVehiclesPerLot) {
            totalOccupancy += (totalVehiclesPerLot[i] / occupancyArray[i])
        }

        var avgOccupancy = Math.round((totalOccupancy / totalVehiclesPerLot.length) * 100)
        avgOccupancy = isNaN(avgOccupancy) ? 0 : avgOccupancy;
        return avgOccupancy
    }

    const barChart = (lot, type) => {
        const currentVehicleCount = totalVehiclesPerLot[activeLots.indexOf(lot["lotNum"])];
        const lotOccupancy = occupancyPerLot[lot["lotNum"]];
        var percentage = Math.round((currentVehicleCount / lotOccupancy) * 100);
        percentage = isNaN(percentage) ? 0 : percentage;
        percentage = type === "average" ? averageOccupancy : percentage;

        var color = ""
        if (percentage <= 25) {
            color = "bg-[#C6F1CA]"
        } else if (percentage <= 80) {
            color = "yellow-gradient"
        } else {
            color = "bg-[#FDC1C0]"
        }

        function marginCalculator(percentage) {
            const value = 150 - (150 - percentage * 1.5);
            if (type === "average") {
                if (percentage === 100) {
                    return value - 92 + "px";
                } else if (percentage < 20) {
                    return value + 6 + "px";
                } else {
                    return value - 82 + "px";
                }
            } else {
                if (percentage === 100) {
                    return value - 55 + "px";
                } else if (percentage < 20) {
                    return value + 6 + "px";
                } else {
                    return value - 45 + "px";
                }
            }
        }

        const width = percentage + "%";
        const margin = marginCalculator(percentage);

        return (
            <div key={"bar"} className="flex flex-row justify-left items-center w-36 h-7 bg-white shadow-md rounded-md my-2 cursor-pointer hover:scale-105 transition-all duration-100">
                <div className={`h-7 rounded-md ${color} p-0 m-0 shadow-md`} style={{ width: width }}></div>
                <div className="absolute p-0 m-0 font-semibold" style={{ marginLeft: margin }}>{type === "average" ? `Avg: ${percentage}` : percentage}%</div>
            </div >
        );
    }

    const OccupancyPie = () => {
        return (
            <div className="flex flex-col items-center h-96 w-96 shadow-lg border bg-white rounded-3xl">
                <h1 className="text-4xl font-bold text-spotGray mb-4 mt-4">Campus Occupancy</h1>
                <div className="w-72">
                    <PieChart
                        data={[{ value: piePercentage, color: "#FFE374" }]}
                        totalValue={100}
                        lineWidth={25}
                        label={({ dataEntry }) => dataEntry.value + "%"}
                        labelStyle={{
                            fontSize: '25px',
                            fontFamily: 'sans-serif',
                            fill: '#323232',
                        }}
                        labelPosition={0}
                        startAngle={-90}
                        background="#F1F1F1"
                        rounded="true"
                    />
                </div>
            </div>
        );
    }

    const Table = () => {
        return (
            <div className="relative flex flex-col items-center w-auto h-fit p-0 bg-white rounded-3xl shadow-lg border">
                <h1 className="text-3xl font-bold text-spotGray mb-4 mt-4">Parking Lot Occupancy</h1>
                <table className="table-auto mb-5">
                    <thead className="shadow-lg pb-6">
                        <tr>
                            <th className="pl-4 pb-2">Lot Number</th>
                            <th className="pb-2">Occupancy</th>
                            <th className="font-light px-2 pb-2">Total Vehicles</th>
                            <th className="font-light px-2 pb-2">Tickets Pending</th>
                            <th className="font-light px-2 pb-2">Tickets Written</th>
                            <th className="px-2 pb-2">Total Violations</th>
                            <th className="pr-4 pb-2">Auto</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {lotData.map((lot, index) => {
                            return (
                                <tr key={index}>
                                    <td className="font-bold">{lot["lotNum"]}</td>
                                    <td className="flex justify-center">{barChart(lot)}</td>
                                    <td>{totalVehiclesPerLot[index]}</td>
                                    <td>{ticketsPendingPerLot[index]}</td>
                                    <td>{ticketsWrittenPerLot[index]}</td>
                                    <td>{violationsPerLot[index]}</td>
                                    <td className="flex justify-center align-middle mb-4 w-10">{autoStatuses[lot["lotNum"]] ? <FaCheckCircle color="#59974D" size={20} /> : <FaCircleXmark color="#D7504D" size={20} />}</td>
                                </tr>
                            );
                        })}
                        <tr className="border-t-2">
                            <td className="font-bold">Totals</td>
                            <td className="flex justify-center">{barChart(lotData[0], "average")}</td>
                            <td>{totalVehiclesPerLot.reduce((total, tickets) => total + tickets, 0)}</td>
                            <td>{ticketsPendingPerLot.reduce((total, tickets) => total + tickets, 0)}</td>
                            <td>{ticketsWrittenPerLot.reduce((total, tickets) => total + tickets, 0)}</td>
                            <td>{violationsPerLot.reduce((total, violations) => total + violations, 0)}</td>
                            <td>{autoFraction}</td>
                        </tr>
                    </tbody>
                    <div className="absolute top-0 right-0 fcc gap-1 border-l border-b rounded-bl-xl">
                        <p className="font-bold pl-2 pt-1 pr-3">Total Lots</p>
                        <p className="">{lotData.length}</p>
                    </div>
                </table>
            </div>
        );
    }

    var autoStatuses = {}
    function getAutoStatuses(lots) {
        for (let i in lots) {
            autoStatuses[lots[i]["lotNum"]] = lots[i]["autoStatus"];
        }
        return autoStatuses;
    }

    // Start by fetching data
    useEffect(() => {
        storeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <div className="flex flex-row items-center justify-center h-screen w-screen">
                <Loader />
            </div>
        );
    }

    var lotNumbers = [];
    const violationData = JSON.parse(sessionStorage.getItem("violationData"));
    const currentOrgData = orgData

    if (currentOrgData.error) {
        return (
            <div className="flex flex-row justify-center h-screen w-screen">
                <h1 className="text-3xl font-bold text-spotGray mt-20">No data available.</h1>
            </div>
        )
    }

    const lotData = currentOrgData ? currentOrgData.lots : null;
    const activeLots = findAllLots(lotData);
    const perLotData = vehiclesPerLot(activeLots, violationData);
    const violationsPerLot = perLotData[0];
    const ticketsPendingPerLot = perLotData[1]
    const ticketsWrittenPerLot = perLotData[2]
    const totalVehiclesPerLot = perLotData[3]
    const occupancyPerLot = perLotData[4][0]
    const averageOccupancy = getAverageOccupancy(perLotData);
    const totalOccupancy = getTotalOccupancy(perLotData);
    const totalVehicles = totalVehiclesPerLot.reduce((total, lot) => total + lot, 0);
    var piePercentage = totalVehicles === null ? 0 : Math.round((totalVehicles / totalOccupancy) * 100)
    piePercentage = totalVehicles === 0 || totalOccupancy === 0 ? 0 : piePercentage;

    getAutoStatuses(lotData);

    const countAutoStatuses = Object.values(autoStatuses).filter(status => status).length;
    const autoFraction = `${countAutoStatuses}/${activeLots.length}`;

    return (
        <div className="flex flex-row flex-wrap justify-center gap-8 w-full mt-10 pl-24 pr-4 font-rubik">
            {loading ? (
                <div className="flex flex-row items-center justify-center h-screen w-screen">
                    <Loader />
                </div>
            ) : (
                <>
                    <OccupancyPie />
                    <Table />
                </>
            )}
        </div>
    );
}

export default Dashboard;