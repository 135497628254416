import './App.css';
import Dashboard from './dashboard/Dashboard';
import MapView from './liveMap/MapView';
import { CurrentViolations } from "./analytics/currentViolations/CurrentViolations";
import { PlateHistory } from "./analytics/plateHistory/PlateHistory";
import { ViolationHistory } from "./analytics/plateHistory/ViolationHistory";
import ViolationEditor from "./analytics/plateHistory/ViolationEditor";
import { MyAccount } from './settings/MyAccount';
import { Users } from './settings/Users';
import { Lots } from './settings/Lots';
import { Permits } from './settings/Permits';
import { Announcements } from './settings/Announcements';
import { Help } from './settings/Help';
import Signup from './login/SignUp';
import Login from './login/Login';
import ResetPassword from './login/ResetPassword';
import ContactUs from './login/ContactUs';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from './Sidebar';
import { Citations } from './analytics/Citations';
import { SinglePermit } from './settings/SinglePermit';
import { Individual } from './analytics/Individual';
import { Appeals } from './analytics/Appeals';
import { SingleCitation } from './analytics/SingleCitation';
import { IndividualPermit } from './analytics/IndividualPermit';
import { Cameras } from './settings/Cameras';
import { ViolationTypes } from './settings/ViolationTypes';

function App() {
    const jsCookie = Cookies.get("accessToken");
    const org = localStorage.getItem("organization");
    const allowedPaths = ["/login", "/contact-us", "/reset-temp-password", "/reset-password"];

    if (jsCookie === undefined || org === null) {
        // console.log("Authenticated:", false);
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove("accessToken");

        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
        }
    }

    return (
        <Router>
            {!allowedPaths.includes(window.location.pathname) ? <Sidebar /> : null}
            <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route path="/map" element={<MapView />} />
                <Route path="/analytics/current-violations" element={<CurrentViolations />} />
                <Route path="/analytics/plate-history" element={<ViolationHistory />} />
                <Route path="/analytics/plate-history/:licensePlate" element={<PlateHistory />} />
                <Route path="/analytics/plate-history/edit" element={<ViolationEditor />} />
                <Route path="/analytics/citations" element={<Citations />} />
                <Route path="/analytics/citations/:citationID" element={<SingleCitation />} />
                <Route path="/analytics/appeals" element={<Appeals />} />
                <Route path="/analytics/users/:user" element={<Individual />} />
                <Route path="/analytics/users/permit/:confirmationNumber" element={<IndividualPermit />} />
                <Route path="/announcements" element={<Announcements />} />
                {/* Login Stuff */}
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/contact-us" element={<ContactUs />} />
                {/* Settings */}
                <Route path="/settings/my-account" element={<MyAccount />} />
                <Route path="/settings/my-account/violation-types" element={<ViolationTypes />} />
                <Route path="/settings/users" element={<Users />} />
                <Route path="/settings/lots" element={<Lots />} />
                <Route path="/settings/lots/cameras" element={<Cameras />} />
                <Route path="/settings/permits" element={<Permits />} />
                <Route path="/settings/permits/:permitType" element={<SinglePermit />} />
                <Route path="/help" element={<Help />} />
            </Routes>
        </Router>
    );
}

export default App;
