export const isObjDifferent = (obj1, obj2) => {
    // Check if both inputs are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 !== obj2;
    }

    // Compare the number of keys in both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return true;
    }

    // Check if values for each key are different
    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            // If the value is an object, recursively check its properties
            if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
                if (isObjDifferent(obj1[key], obj2[key])) {
                    return true;
                }
            } else {
                return true;
            }
        }
    }

    return false;
}