import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchAllData } from "../../utility/fetcher";
import { Loading } from "../../utility/Loading";

export const ViolationHistory = () => {
    const [historyData, setHistoryData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    sessionStorage.setItem("currentPlate", "");

    // Format given date to a readable format
    const getFormattedDate = (input) => {
        const mostRecentDate = new Date(input);

        const currentDate = new Date();
        const isToday = mostRecentDate.getDate() === currentDate.getDate() &&
            mostRecentDate.getMonth() === currentDate.getMonth() &&
            mostRecentDate.getFullYear() === currentDate.getFullYear();

        const formattedDate = isToday ? "Today" : mostRecentDate.toDateString();
        return formattedDate;
    }

    // Create bar chart
    const barChart = (percentage) => {
        var color = ""
        if (percentage <= 25) {
            color = "bg-[#C6F1CA]"
        } else if (percentage <= 80) {
            color = "bg-[#FFE374]"
        } else {
            color = "bg-[#FDC1C0]"
        }

        function marginCalculator(percentage) {
            if (percentage < 50) {
                const value = percentage * 1.5 + 10
                return value + "px"
            } else {
                const value = 150 - (150 - percentage * 1.5) - 55
                return value + "px"
            }
        }

        return (
            <div key={"bar"} className="flex flex-row justify-left items-center w-36 h-7 bg-white shadow-md rounded-md my-2 hover:scale-105 transition-all duration-100 cursor-default">
                <div key={"fill"} className={`h-7 rounded-md ${color} p-0 m-0 shadow-md cursor-default`} style={{ width: `${percentage}%` }}></div>
                <div key={"percentage"} className="absolute p-0 m-0 font-semibold cursor-default" style={{ marginLeft: marginCalculator(percentage) }}>{percentage}%</div>
            </div>
        )
    }

    // Create table data
    const tableData = (listData) => {
        return (
            listData.map((item, index) => {
                const plate = item["licensePlate"];

                return (
                    <tr key={index} className="font-rubik">
                        <td className="text-spotGray underline cursor-pointer">
                            <Link
                                to={`/analytics/plate-history/${plate}`}
                                onClick={() => handleLinkClick(plate)}
                            >
                                {plate}
                            </Link>
                        </td>
                        <td>{item["times_on_campus"]}</td>
                        <td>{item["ticketsWritten"]}</td>
                        <td style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>{barChart(item["violationPercentage"])}</td>
                        <td>{getFormattedDate(item["date_last_seen"])}</td>
                    </tr>
                );
            })
        );
    }

    // Navigate to a specific plate's history page
    const handleLinkClick = (plate) => {
        sessionStorage.setItem("currentPlate", plate);
    }

    // Update search term
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const storeData = async () => {
        const orgID = JSON.parse(localStorage.getItem("orgData")).orgID;
        const path = { "historyData": `/${orgID}/history` }
        const response = await fetchAllData(path);
        const data = await response["historyData"].sort((a, b) => new Date(b["date_last_seen"]) - new Date(a["date_last_seen"]));
        setHistoryData(data);
        setLoading(false);
    }

    useEffect(() => {
        storeData();
    }, []);

    if (loading) return <Loading />

    let filteredListData = [];
    // Filter data based on search term
    filteredListData = historyData ? historyData.filter((item) =>
        item.licensePlate.toLowerCase().includes(searchTerm.toLowerCase()) && item.licensePlate !== "#UNKN#"
    ) : [];

    return (
        <div>
            <div key={"history-container"} className="flex flex-col m-4 ml-24 font-rubik">
                <div className="flex flex-row justify-between p-4 sticky top-0 bg-white z-40">
                    <h1 className="text-3xl font-bold ml-3">History</h1>
                    <input
                        type="text"
                        placeholder="Search License Plate"
                        className="rounded-md border-2 border-gray-300 pl-2 outline-spotYellow"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>
                {filteredListData.length === 0 ? (
                    <p className="flex items-center font-medium text-lg px-14 py-4">No Plate History</p>
                ) : (
                    <table >
                        <thead>
                            <tr className="text-lg sticky top-16 bg-white z-40">
                                <th className="pb-2">License Plate</th>
                                <th className="pb-2">Times on Campus</th>
                                <th className="pb-2">Tickets Written</th>
                                <th className="pb-2">Violation %</th>
                                <th className="pb-2">Most Recent Sighting</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {tableData(filteredListData)}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}