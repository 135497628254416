import React from "react";
import { PiNotePencilBold } from "react-icons/pi";
import { PiKeyReturnBold } from "react-icons/pi";
import { FaBalanceScale } from "react-icons/fa";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BackArrow } from "../utility/BackArrow";

function dateFormatter(date) {
    const dateTime = new Date(date);
    const day = dateTime.getDate();
    const month = dateTime.toLocaleString('default', { month: 'long' });
    const time = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const dateObj = { dateTime, day, month, time };
    return dateObj;
}

export const SingleCitation = () => {
    const citation = JSON.parse(sessionStorage.getItem("currentCitation"));
    const { dateTime: citationDateTime, day: citationDay, month: citationMonth, time: citationTime } = dateFormatter(citation.timeWritten);

    // Initialize variables
    let appealed = false;
    let days1 = 0;
    let appealDateTime = "";
    let appealDay = "";
    let appealMonth = "";
    let appealTime = "";

    var appealResponded = false;
    let days2 = 0;
    let responseDay = "";
    let responseMonth = "";
    let responseTime = "";

    var paid = false;
    let days3 = 0;
    let paidDay = "";
    let paidMonth = "";
    let paidTime = "";

    try {
        const allAppeals = citation.appeals;
        let appeal = {}
        if (allAppeals) {
            appeal = citation.appeals[0];

            if (citation.appeals.length > 0) {
                appealed = true;

                const {
                    dateTime: appealDate,
                    day: formattedAppealDay,
                    month: formattedAppealMonth,
                    time: formattedAppealTime
                } = dateFormatter(appeal.appealDate);

                appealDateTime = appealDate;
                appealDay = formattedAppealDay;
                appealMonth = formattedAppealMonth;
                appealTime = formattedAppealTime;

                days1 = Math.floor((appealDate - citationDateTime) / (1000 * 60 * 60 * 24));
            }



            if (appeal.status === "Denied" || appeal.status === "Approved") {
                appealResponded = true;

                const {
                    dateTime: responseDate,
                    day: formattedResponseDay,
                    month: formattedResponseMonth,
                    time: formattedResponseTime
                } = dateFormatter(appeal.replyDate);

                responseDay = formattedResponseDay;
                responseMonth = formattedResponseMonth;
                responseTime = formattedResponseTime;

                days2 = Math.floor((responseDate - appealDateTime) / (1000 * 60 * 60 * 24));
            }
        }
        if (citation.paid === true) {
            paid = true;



            const {
                dateTime: datePaid,
                day: formattedPaidDay,
                month: formattedPaidMonth,
                time: formattedPaidTime
            } = dateFormatter(citation.datePaid);

            paidDay = formattedPaidDay;
            paidMonth = formattedPaidMonth;
            paidTime = formattedPaidTime;

            days3 = Math.floor((datePaid - citationDateTime) / (1000 * 60 * 60 * 24));
        }

        const betweenLine = (days) => {
            return (
                <div className="fcc mb-4 text-[12px] text-gray-500">
                    <p>{days} {days > 1 || days === 0 ? "days" : "day"}</p>
                    <hr className="w-20 mx-2 border-t-1 border-gray-300" />
                </div>
            );
        }

        return (
            <div className="ml-20 w-screen font-rubik p-16">
                <BackArrow path="/analytics/citations" />
                <div id={"title"} className="flex flex-col gap-1">
                    <h1 className="text-3xl w-full text-left mb-2"><strong>Citation ID:</strong> {citation.citationID}</h1>
                    <p className="text-xl font-light"><strong>License Plate:</strong> {citation.licensePlate}</p>
                    <p className="text-xl font-light"><strong>Date Written:</strong> {new Date(citation.timeWritten).toLocaleString()}</p>
                    <p className="text-xl font-light"><strong>Status:</strong> {citation.paid ? "PAID" : "UNPAID"}</p>
                    <p className="text-xl font-light"><strong>Citation Type:</strong> {citation.type}</p>
                </div>
                <hr className="w-[95%] my-6" />
                <div id={"history"} className="flex flex-row justify-start w-full">
                    <div id={"citation"} className="flex flex-col justify-between items-center py-2 px-2 h-48 w-48 rounded-2xl border cursor-pointer transition-all duration-300 ease-in-out hover:shadow-md">
                        <div className="flex flex-row relative justify-between items-center w-full h-full">
                            <div className="flex flex-col justify-start items-center absolute top-0 min-w-fit">
                                <h2 className="text-md text-gray-500 -mb-2">{citationMonth}</h2>
                                <h2 className="text-6xl font-bold text-spotGray -mb-2">{citationDay}</h2>
                                <h2 className="fcc text-gray-500">{citationTime}</h2>
                            </div>
                            <PiNotePencilBold size={72} className="text-gray-500 absolute top-[40%] right-2" />
                        </div>
                        <div>
                            <p className="text-xl text-spotGray">Citation Written</p>
                        </div>
                    </div>
                    {appealed ? (
                        <>
                            {betweenLine(days1)}
                            <div id={"citation"} className="flex flex-col justify-between items-center py-2 px-2 h-48 w-48 rounded-2xl border cursor-pointer transition-all duration-300 ease-in-out hover:shadow-md">
                                <div className="flex flex-row relative justify-between items-center w-full h-full">
                                    <div className="flex flex-col justify-start items-center absolute top-0 min-w-fit">
                                        <h2 className="text-md text-gray-500 -mb-2">{appealMonth}</h2>
                                        <h2 className="text-6xl font-bold text-spotGray -mb-2">{appealDay}</h2>
                                        <h2 className="fcc text-gray-500">{appealTime}</h2>
                                    </div>
                                    <PiKeyReturnBold size={72} className="text-gray-500 absolute top-[40%] right-2" />
                                </div>
                                <div>
                                    <p className="text-xl text-spotGray">Appealed</p>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {appealResponded ? (
                        <>
                            {betweenLine(days2)}
                            <div id={"citation"} className="flex flex-col justify-between items-center py-2 px-2 h-48 w-48 rounded-2xl border cursor-pointer transition-all duration-300 ease-in-out hover:shadow-md">
                                <div className="flex flex-row relative justify-between items-center w-full h-full">
                                    <div className="flex flex-col justify-start items-center absolute top-0 min-w-fit">
                                        <h2 className="text-md text-gray-500 -mb-2">{responseMonth}</h2>
                                        <h2 className="text-6xl font-bold text-spotGray -mb-2">{responseDay}</h2>
                                        <h2 className="fcc text-gray-500">{responseTime}</h2>
                                    </div>
                                    <FaBalanceScale size={72} className={`${appeal.status === "Approved" ? "text-green-200" : "text-red-200"} absolute top-[40%] right-2`} />
                                </div>
                                <div>
                                    <p className="text-xl text-spotGray">Appeal {appeal.status}</p>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {paid ? (
                        <>
                            {betweenLine(days3)}
                            <div id={"citation"} className="flex flex-col justify-between items-center py-2 px-2 h-48 w-48 rounded-2xl border cursor-pointer transition-all duration-300 ease-in-out hover:shadow-md">
                                <div className="flex flex-row relative justify-between items-center w-full h-full">
                                    <div className="flex flex-col justify-start items-center absolute top-0 min-w-fit">
                                        <h2 className="text-md text-gray-500 -mb-2">{paidMonth}</h2>
                                        <h2 className="text-6xl font-bold text-spotGray -mb-2">{paidDay}</h2>
                                        <h2 className="fcc text-gray-500">{paidTime}</h2>
                                    </div>
                                    <RiMoneyDollarCircleFill size={72} className={`text-green-200 absolute top-[40%] right-2`} />
                                </div>
                                <div>
                                    <p className="text-xl text-spotGray">Citation Paid</p>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    } catch (error) {
        console.log(error);
        return (
            <div className="pl-32 w-full font-rubik p-16">
                <div id={"title"} className="flex flex-col gap-1">
                    <h1 className="text-3xl w-full text-left mb-2"><strong>Citation ID:</strong> {citation.citationID}</h1>
                    <p className="text-xl font-light"><strong>License Plate:</strong> {citation.licensePlate}</p>
                    <p className="text-xl font-light"><strong>Date Written:</strong> {new Date(citation.timeWritten).toLocaleString()}</p>
                    <p className="text-xl font-light"><strong>Status:</strong> {citation.paid ? "PAID" : "UNPAID"}</p>
                    <p className="text-xl font-light"><strong>License Plate:</strong> {citation.type}</p>
                </div>
                <hr className="w-[100%] my-6" />
                <div id={"history"} className="flex flex-row justify-start w-full">
                    <div id={"citation"} className="flex flex-col justify-between items-center py-2 px-2 h-48 w-48 rounded-2xl border cursor-pointer transition-all duration-300 ease-in-out hover:shadow-md">
                        <div className="flex flex-row relative justify-between items-center w-full h-full">
                            <div className="flex flex-col justify-start items-center absolute top-0 min-w-fit">
                                <h2 className="text-md text-gray-500 -mb-2">{citationMonth}</h2>
                                <h2 className="text-6xl font-bold text-spotGray -mb-2">{citationDay}</h2>
                                <h2 className="fcc text-gray-500">{citationTime}</h2>
                            </div>
                            <PiNotePencilBold size={72} className="text-gray-500 absolute top-[40%] right-2" />
                        </div>
                        <div>
                            <p className="text-xl text-spotGray">Citation Written</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}