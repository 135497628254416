import React, { useEffect, useState } from "react";
import { FaCheck, FaArrowLeft, FaEdit } from "react-icons/fa";
import ViolationEditor from "./ViolationEditor";
import { fetchAllData } from "../../utility/fetcher";
import { copyToClipboard } from "../../utility/copyToClipboard";
import "../analytics.css";
import { Toaster } from "sonner";
import { Loading } from "../../utility/Loading";

export const PlateHistory = () => {
    const plate = sessionStorage.getItem("currentPlate");
    const [showEditor, setShowEditor] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getPlateHistory = async () => {
        const path = { "historyData": `/000001/history?licensePlate=${plate}` }
        const response = await fetchAllData(path);
        const data = await response["historyData"].history
        const sortedData = data.sort((a, b) => new Date(b["timeIn"]) - new Date(a["timeIn"]));

        setHistoryData(sortedData);
        setLoading(false);
    }

    useEffect(() => {
        getPlateHistory();
        // eslint-disable-next-line
    }, []);

    const toDate = (input) => {
        if (input === "N/A" || input === "") return ("-");
        const date = new Date(input);
        return date.toDateString();
    }

    const handleEdit = (item) => {
        sessionStorage.setItem("editData", JSON.stringify(item));
        setShowEditor(true);
        //window.location.href = "/analytics/violation-history/edit";
    }

    const tableData = (sortedData) => {
        return (
            sortedData.map((item) => {
                return (
                    <tr className="border-b">
                        <td>{toDate(item["timeIn"])}</td>
                        <td>{toDate(item["timeOut"])}</td>
                        <td>{toDate(item["ticketWrittenTime"])}</td>
                        <td>{item["employee"]}</td>
                        <td>{item["lot"]}</td>
                        <td>{item["spot"]}</td>
                        <td>{item["ticketWritten"] === "True" ? <FaCheck className="w-full" /> : null}</td>
                        <td>{item["violationType"]}</td>
                        <td>{formatFee(item["fee"])}</td>
                        <td>
                            <button onClick={() => handleEdit(item)} className="bg-transparent cursor-pointer text-gray-500 hover:text-white hover:bg-gray-500 p-1 rounded-lg">
                                <FaEdit />
                            </button>
                        </td>
                    </tr>
                );
            })
        );
    }

    const onButtonClick = () => {
        setShowEditor(false);
    }

    if (loading) return <Loading />

    const formatFee = (fee) => {
        if (fee === "") return "";
        return `$${parseFloat(fee).toFixed(2)}`;
    };

    return (
        <div>
            <div className="flex flex-col ml-24 mt-4 mb-8 font-rubik">
                <button key={"backButton"} onClick={() => window.history.back()} className="flex flex-row items-center w-fit p-2 rounded-md hover:text-gray-400">
                    <FaArrowLeft />
                    <span className="font-bold ml-1"> Back</span>
                </button>
                <div key={"historyTile"}>
                    <h1 className="text-3xl font-bold">Plate History</h1>
                    <h1 className="text-md font-medium cursor-pointer" onClick={() => copyToClipboard(plate)}>{plate}</h1>
                    <table className="m-auto mt-4 w-[95%] text-center">
                        <thead>
                            <tr style={{
                                borderBottom: "1px solid #323232",
                                borderTop: "1px solid #323232",
                            }}>
                                <th>Date In</th>
                                <th>Date Out</th>
                                <th>Ticket Written Time</th>
                                <th>Employee</th>
                                <th>Lot</th>
                                <th>Spot</th>
                                <th>Ticket Received</th>
                                <th>Violation Type</th>
                                <th>Fee</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData(historyData)}
                        </tbody>
                    </table>
                </div>
            </div>
            {showEditor ?
                <div>
                    <div
                        style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: "100",
                        }}>
                        <ViolationEditor onButtonClick={onButtonClick} />
                    </div>
                </div>
                : null
            }
            <Toaster richColors />
        </div>
    );
}