import React, { useEffect, useState } from "react";
import { CarTile } from "../utility/CarTile";
import { Link } from "react-router-dom";

export const Individual = () => {
    const [userCitations, setUserCitations] = useState([]);
    const userData = JSON.parse(sessionStorage.getItem("individualUser"));
    const currentOrg = JSON.parse(localStorage.getItem("orgData")).orgName;
    const { email, permits, settings, citations } = userData;
    const activePermits = permits.filter(permit => new Date(permit.expirationDate) >= new Date());
    const vehicles = settings.vehicles;
    const personalInfo = settings.personalInfo;
    const { firstName, lastName } = personalInfo;

    useEffect(() => {
        let localCitations = [];
        for (let i in citations) {
            if (citations[i].location === currentOrg) {
                localCitations.push(citations[i]);
            }
        }
        setUserCitations(localCitations);
        // eslint-disable-next-line
    }, []);

    const permitTile = (permit) => {
        console.log(permit)
        return (
            <Link to={`/analytics/users/permit/${permit.confirmationNumber}`} className="flex flex-col gap-4 mt-4 border border-gray-100 rounded-xl shadow-md p-8 w-[80%] hover:bg-gray-100 cursor-pointer" onClick={() => sessionStorage.setItem("individualPermit", JSON.stringify(permit))}>
                <h3 className="text-2xl w-fit font-semibold">{permit.type}</h3>
                <h2 className="text-xl w-fit">Expiration: {new Date(permit.expirationDate).toLocaleDateString()}</h2>
            </Link>
        );
    }

    const citationTile = (citation) => {
        let color = "bg-gray-100";
        let status = "UNPAID";

        if (citation.paid) {
            color = "bg-green-100";
            status = "Paid";
        } else if (citation.appeals && citation.appeals.length > 0) {
            const lastAppeal = citation.appeals[citation.appeals.length - 1];
            if (lastAppeal.status === "Approved") {
                status = "Appeal Approved";
            } else if (lastAppeal.status === "Denied") {
                color = "bg-red-100";
                status = "Appeal Denied";
            } else {
                color = "bg-yellow-100";
                status = "Appeal Pending";
            }
        } else {
            color = "bg-red-100";
        }

        return (
            <div className={`flex flex-col gap-1 mt-4 border border-gray-100 rounded-xl shadow-md p-6 w-[80%] ${color}`}>
                <h3 className="text-2xl w-fit font-semibold pb-2">{citation.type}</h3>
                <h3 className="text-lg w-fit"><strong>Citation ID:</strong> {citation.citationID}</h3>
                <h2 className="text-lg w-fit"><strong>Date:</strong> {new Date(citation.timeWritten).toLocaleDateString()}</h2>
                <h2 className="text-lg w-fit"><strong>Spot:</strong> {citation.lot}-{citation.spot}</h2>
                <h2 className="text-lg w-fit"><strong>Fee:</strong> ${parseFloat(citation.fee).toFixed(2)}</h2>
                <h2 className="text-lg w-fit"><strong>Status:</strong> {status}</h2>
            </div>
        );
    };

    return (
        <div className="flex flex-col ml-24 p-8 h-screen font-rubik">
            <div className="flex flex-col w-full py-8 border-b border-gray-300">
                <h1 className="font-bold text-4xl w-fit pb-2">{firstName} {lastName}</h1>
                <h2 className="text-2xl w-fit">{email}</h2>
            </div>
            <div className="flex flex-row h-full">
                <div className="flex flex-col gap-2 flex-1 w-full mt-8">
                    <h1 className="font-bold text-3xl w-fit pb-2">Vehicles</h1>
                    {vehicles.map(vehicle => (
                        <CarTile vehicle={vehicle} />
                    ))}
                </div>
                <div className="border-l border-gray-300 mr-12 mt-12"></div>
                <div className="flex flex-col gap-2 flex-1 w-full mt-8">
                    <h1 className="font-bold text-3xl w-fit pb-2">Active Permits</h1>
                    {activePermits.map(permit => (
                        permitTile(permit)
                    ))}
                </div>
                <div className="border-l border-gray-300 px-12 mt-12"></div>
                <div className="flex flex-col flex-1 w-full mt-8">
                    <h1 className="font-bold text-3xl w-fit pb-2">Citations</h1>
                    {userCitations.length === 0 ? <h1 className="text-2xl w-full text-left pt-4">No citations found</h1> :
                        userCitations.map(citation => (
                            citationTile(citation)
                        ))
                    }
                </div>
            </div>
        </div>
    );
}