import React from "react";
import { Toaster } from "sonner";
import { LuCopy } from "react-icons/lu";
import { BackArrow } from "../utility/BackArrow";
import { copyToClipboard } from "../utility/copyToClipboard";
import { CarTile } from "../utility/CarTile";

export const IndividualPermit = () => {
    const permit = JSON.parse(sessionStorage.getItem("individualPermit"));
    const permittedVehicles = permit.vehicles;
    const userData = JSON.parse(sessionStorage.getItem("individualUser"));
    const userVehicles = userData.settings.vehicles;
    const dateofPurchase = new Date(permit.purchaseDate).toLocaleDateString();
    const expirationDate = new Date(permit.expirationDate).toLocaleDateString();
    const purchasePrice = permit.price
    const formattedPrice = purchasePrice.includes("$") ? purchasePrice : `$${parseFloat(purchasePrice).toFixed(2)}`;

    const vehicleArray = []
    for (let i in permittedVehicles) {
        for (let j in userVehicles) {
            if (permittedVehicles[i] === userVehicles[j].licensePlate) {
                vehicleArray.push(userVehicles[j])
            }
        }
    }

    return (
        <div className="flex flex-col ml-24 p-8 h-screen font-rubik">
            <BackArrow path="/analytics/users" />
            <div className="flex flex-col w-full py-8 border-b border-gray-300">
                <h1 className="font-bold text-4xl w-fit pb-2">{permit.type} - {permit.description}</h1>
                <div className="flex flex-row justify-start gap-2 items-center cursor-pointer" onClick={() => copyToClipboard(permit.confirmationNumber)}>
                    <h2 className="text-xl w-fit"><strong className="cursor-default">Confirmation Number: </strong> {permit.confirmationNumber}</h2>
                    <LuCopy size={15} className="text-gray-500" />
                </div>
                <h2 className="text-xl w-fit"><strong>Date of Purchase: </strong> {dateofPurchase}</h2>
                <h2 className="text-xl w-fit"><strong>Expiration Date: </strong> {expirationDate}</h2>
                <h2 className="text-xl w-fit"><strong>Purchase Price: </strong> {formattedPrice}</h2>
            </div>
            <div className="flex flex-col gap-2 flex-1 w-full mt-8 max-w-[600px]">
                <h1 className="font-bold text-3xl w-fit pb-2">Assigned Vehicles</h1>
                {vehicleArray.map(vehicle => (
                    <CarTile vehicle={vehicle} />
                ))}
            </div>
            <Toaster />
        </div>
    );
}