import React from "react";
import { SingleSetting } from "./singleSetting";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { Toaster } from "sonner";

//TODO: Change the vibe of this page to make it look similar to the personal info page on the nav app
//TODO: Also rework this so it doesn't have a save button for each item

export const MyAccount = () => {
    const currentOrgDataRaw = localStorage.getItem("orgData");
    if (!currentOrgDataRaw || currentOrgDataRaw === "undefined") {
        return (
            <div className="flex flex-col m-4 ml-20">
                <div className="flex flex-col w-full text-center mt-10 text-3xl font-bold">Organization Settings</div>
                <div className="flex flex-col w-full text-center mt-10">Unable to load settings.</div>
            </div>
        )
    }
    const currentOrgData = JSON.parse(currentOrgDataRaw);
    const removeList = ["lots"]
    const filteredOrgData = Object.fromEntries(
        Object.entries(currentOrgData).filter(([key]) => !removeList.includes(key))
    );

    const order = [
        "orgName",
        "violationTimeGoal",
        "baseFee",
        "defaultMapZoom",
        "orgCoordinates",
        "repeatOffenderThreshold"
    ];

    const orderedOrgData = order.map(key => [key, filteredOrgData[key]]);
    const orderedFilteredOrgData = Object.fromEntries(orderedOrgData);

    const filteredOrderedOrgData = Object.fromEntries(
        Object.entries(orderedFilteredOrgData).filter(([key]) => order.includes(key))
    );

    return (
        <div className="flex flex-col m-6 ml-24 font-rubik">
            <div className="flex flex-col w-full text-center mt-10 mb-4 text-3xl font-bold">Organization Settings</div>
            {Object.entries(filteredOrderedOrgData).map(([key, value]) => (
                SingleSetting(key, value)
            ))}
            <Link to="/settings/my-account/violation-types" className="flex flex-row justify-between items-center w-full shadow-md rounded-lg border my-2 p-2 pl-4 py-4 hover:bg-gray-100 cursor-pointer">
                <h2 className='font-bold text-lg'>Violation Types</h2>
                <div className="text-spotGray mr-8 p-1 rounded-lg">
                    <IoIosArrowForward size={32} />
                </div>
            </Link>
            <Toaster position='bottom-right' richColors />
        </div>
    );
};
